import React, { useContext, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import './styles/FeedbackSurvey.css'; // Importing CSS file for styling
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import { UserContext } from '../UserContext';

function FeedbackSurvey() {
  const {user, token} = useContext(UserContext);
  const [rating, setRating] = useState(0);
  const [remarks, setRemarks] = useState(''); 
  const navigate = useNavigate();

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!remarks.trim()) {
        notifyError(NotifyMessages.reviewRequired,'bottom-center');
        return;
    }

    await fetch(`${process.env.REACT_APP_API_URL}/feedback/${user.id}`,{
        method:'POST',
        headers:{
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({rating, remarks}),
    }
    )

    notifySuccess(NotifyMessages.reviewSubmitted, 'bottom-center');
    setTimeout(() => {
        navigate('/');
    },2000)
  };

  const handleSkip = () => {
    // Navigate to another page immediately
    navigate('/');
  };

  return (
    <div className="review-container">
      <Header />
      <ToastContainer/>
      <div className="main-content">
        <h3 className='overall-heading'>Provide Your Feedback</h3>

        <div className="rating-section">
          <h3>How would you rate your experience with the Route-Planner application?</h3>
          <div className="star-rating">
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                className={`star ${index < rating ? 'filled' : ''}`}
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
        </div>

        <div className="rating-section">
          <h3>Did you encounter any issues while using the application?</h3>
          <textarea
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter your remarks or suggestions"
          />
        </div>

        <div className="button-group">
          <button onClick={handleSubmit} className="submit-button">Submit</button>
          <button onClick={handleSkip} className="skip-button">Skip</button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FeedbackSurvey;
