import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';  // Importing UserContext for managing user state
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail} from "firebase/auth";  // Firebase authentication methods
import { auth } from '../components/firebaseAuth';  // Firebase authentication instance
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import './styles/Login.css';  // Styles for the Login component
import GetIP from '../components/GetIP';  // Component to get user's IP address
import title from '../assets/title.png';  // Title image

// Function to get browser details
const getBrowserDetails = () => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.userAgentData?.platform,
    language: navigator.language,
    vendor: navigator.vendor,
  };
};

export default function Login() {
  const [email, setEmail] = useState('');  // State for user email input
  const [password, setPassword] = useState('');  // State for user password input
  const { user, setUser, setToken, setRefreshToken } = useContext(UserContext);  // Using context to set user state
  const navigate = useNavigate();  // Navigation hook for redirecting after login
  const [passwordVisible, setPasswordVisible] = useState(false);  // State to toggle password visibility
  const [ip, setIp] = useState('');  // State to store user's IP address

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to handle form submission for email/password login
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation for email format
    if (!email.endsWith('@gmail.com') && !email.endsWith('@navriti.com')) {
      notifyError(NotifyMessages.invalidEmail, 'top-right');
      return;
    }

    try {
      // Get browser details
      const browserDetails = getBrowserDetails();
      
      // Sign in with email and password using Firebase auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const currUser = userCredential.user;
      const accessToken = await currUser.getIdToken(/* forceRefresh */ true);

      // Send login data to backend API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          email: email,
          password: password,
          browserDetails: browserDetails,
          ipAddress: ip,
        }),
      });

      // Handle successful login
      if (response.ok) {
        notifySuccess(NotifyMessages.loginSuccess, 'top-right');
        const data = await response.json();
        const {accessToken, refreshToken, ...newData}=data;
     
        setTimeout(() => {
          setUser(newData);  // Set user data in context
          setToken(data.accessToken);  // Set access token in context
          setRefreshToken(data.refreshToken);
          navigate('/');  // Redirect to profile page after 2 seconds
        }, 2000);
      } else {
        // Handle login failure
        notifyError(NotifyMessages.loginError, 'top-right');
        console.error('Login failed');
      }
    } catch (error) {
      // Handle login error
      notifyError(NotifyMessages.loginError, 'top-right');
      console.error('Login failed:', error);
    }
  };

  // Function to handle Google sign-in
  const handleSignIn = async (event) => {
    event.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    try {
      // Get browser details
      const browserDetails = getBrowserDetails();

      // Sign in with Google popup using Firebase auth
      const userCredential = await signInWithPopup(auth, provider);
      const currUser = userCredential.user;
      // Get access token
      const accessToken = await currUser.getIdToken(/* forceRefresh */ true);
      
      // Send Google sign-in data to backend API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          email: currUser.email,
          browserDetails: browserDetails,
          ipAddress: ip,
        }),
      });

      // Handle successful Google sign-in
      if (response.ok) {
        notifySuccess(NotifyMessages.loginSuccess, 'top-right');
        const data = await response.json();
        const {accessToken, refreshToken, ...newData}=data;
        setTimeout(() => {
          setUser(newData);  // Set user data in context
          setToken(data.accessToken);  // Set access token in context
          setRefreshToken(data.refreshToken);
          navigate('/');  // Redirect to profile page after 2 seconds
        }, 2000);
      } else {
        // Handle Google sign-in failure
        notifyError(NotifyMessages.loginError, 'top-right');
        console.error('Invalid credentials');
      }
    } catch (error) {
      // Handle Google sign-in error
      notifyError(NotifyMessages.googleSignInError, 'top-right');
      console.error('Error during login:', error);
    }
  };

  // Function to handle password reset
  const handlePasswordReset = async () => {
    if (!email) {
      notifyError(NotifyMessages.enterEmail, 'top-right');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      notifySuccess(NotifyMessages.passwordResetSent, 'top-right');
    } catch (error) {
      notifyError(NotifyMessages.passwordResetError, 'top-right');
      console.error('Password reset error:', error);
    }
  };

  return (
    <div className='container forms'>
      <div className="form login">
        <ToastContainer />  {/* Error notification container */}
        <GetIP setIp={setIp} />  {/* Component to get user's IP address */}
        <div className="form-content">
          <img src={title} alt='Title' />  {/* Title image */}
          <form onSubmit={handleSubmit}>
            <div className="field input-field">
              <input 
                type="email" 
                placeholder="Email" 
                className="input" 
                onChange={(event) => setEmail(event.target.value)} 
                required 
                autoComplete="username"
              />
            </div>
            <div className="field input-field">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password"
                className="password"
                onChange={(event) => setPassword(event.target.value)}
                required
                autoComplete="current-password"
              />
              <i
                className={`bx ${passwordVisible ? 'bx-show' : 'bx-hide'} eye-icon`}
                onClick={handleTogglePasswordVisibility}
              ></i>
            </div>
            <div className="form-link">
                <button onClick={handlePasswordReset} className="forgot-pass">Forgot password?</button>
            </div>
            <div className="field button-field">
              <button>Login</button>  {/* Login button */}
            </div>
          </form>
          <div className="form-link">
            <span>
              Don't have an account?{' '}
              <Link to='/register' className="link signup-link">
                Signup
              </Link>
            </span>
          </div>
        </div>
        <div className="line"></div>  {/* Divider line */}
        <div className="media-options">
          <button onClick={handleSignIn} className="field google">
            <img src="https://cdn.icon-icons.com/icons2/2224/PNG/512/google_logo_icon_134448.png" alt="Google Logo" className="google-img" />
            <span>Login with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
}
