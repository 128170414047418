import React, { useState, useContext } from 'react'; // Import React, useState, and useContext hooks
import { useNavigate } from 'react-router-dom'; // Import Link for navigation and useNavigate for programmatic navigation
import { UserContext } from '../UserContext'; // Import UserContext for user state management
import { createUserWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth functions
import { auth } from '../components/firebaseAuth'; // Import Firebase auth instance
import './styles/Register.css'; // Import CSS for styling
import title from '../assets/title.png';
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed


export default function Register() {
  // State variables for email, password, confirmPassword, password visibility, IP address, and user context
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useContext(UserContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate(); // Hook for navigation

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to handle form submission for registration
  const handleClick = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // Password validation pattern

    // Validations
    if (!email.endsWith('@gmail.com') && !email.endsWith('@navriti.com')) {
      notifyError(NotifyMessages.invalidEmail,'top-right');
      return;
    }
    if (password !== confirmPassword) {
      notifyError(NotifyMessages.passwordsNotMatching,'top-right');
      return;
    }
    if (!passwordPattern.test(password)) {
      notifyError(NotifyMessages.weakPassword,'top-right');
      return;
    }
    
    try {
      // Create user with email and password using Firebase auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // API request to store user data in the database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Send token for backend verification
        },
        body: JSON.stringify({ 
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        // Registration successful, notify and redirect
        notifySuccess(NotifyMessages.registrationSuccessful,'top-right');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        // Handle registration failure
        console.error('Registration failed');
      }
    } catch (error) {
      // Handle different registration errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          notifyError(NotifyMessages.emailAlreadyInUse,'top-right');
          break;
        case 'auth/invalid-email':
          notifyError(NotifyMessages.invalidEmailAddress,'top-right');
          break;
        case 'auth/operation-not-allowed':
          notifyError(NotifyMessages.operationNotAllowed,'top-right');
          break;
        case 'auth/weak-password':
          notifyError(NotifyMessages.weakPasswordError,'top-right');
          break;
        default:
          notifyError(NotifyMessages.registrationError,'top-right');
          console.error('Registration failed:', error);
          break;
      }
    }
  };

  

  return (
    <div className='container forms show-signup'>
      <div className="form signup">
        <ToastContainer /> {/* Toast container for notifications */}
        <div className="form-content">
          <img src={title} alt="Title" /> {/* Optional title image */}
          <header style={{fontSize:'20px'}}>Create Admin</header>
          <form onSubmit={handleClick}>
            {/* Email input field */}
            <div className="field input-field">
              <input type="email" placeholder="Email" className="input" onChange={(event) => setEmail(event.target.value)} required />
            </div>

            {/* Password input field */}
            <div className="field input-field">
              <input type="password" placeholder="Create password" className="password" onChange={(event) => setPassword(event.target.value)} required />
            </div>

            {/* Confirm password input field */}
            <div className="field input-field">
              <input type="password" placeholder="Confirm password" className="password" onChange={(event) => setConfirmPassword(event.target.value)} required />
              <i
                className={`bx ${passwordVisible ? 'bx-show' : 'bx-hide'} eye-icon`}
                onClick={handleTogglePasswordVisibility}
              ></i>
            </div>
            
            {/* Signup button */}
            <div className="field button-field">
              <button>Create Admin</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
}
