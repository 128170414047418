import React, { useState, useContext, useEffect } from 'react';
import './styles/Request.css'; // Importing custom CSS for styling
import Header from '../components/Header'; // Importing Header component
import Footer from '../components/Footer'; // Importing Footer component
import { useNavigate, useParams } from 'react-router-dom'; // Importing navigation hooks
import { UserContext } from '../UserContext'; // Importing UserContext to get user information
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import {FaCloudUploadAlt} from 'react-icons/fa';
import { MdDelete } from "react-icons/md";

function ReopenRequest() {
  const { user, token } = useContext(UserContext); // Getting user and token from UserContext
  
  const navigate = useNavigate(); // Hook for navigation
  const { requestId } = useParams(); // Get request ID from URL params

  // Initial form state
  const [formData, setFormData] = useState({
    supportNeeded: '',
    individuals: [{ name: '', type: '', project: '', vertical: '', purpose: '', otherPurpose: '' }],
    source: '',
    destination: '',
    fromDate: '',
    toDate: '',
    batchId: '',
    center: '',
    client: '',
    costCenter: '',
    otherCostCenter:'',
    approvedFrom: '',
    approvedDate: '',
    approvedProof: null,
    email: user ? user.email : '',
    userId: user ? user.id : null,
  });

  // Effect to fetch request data if editing an existing request
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing request data for editing
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        });

        if (!response.ok) {
        throw new Error('Failed to fetch request data');
        }

        const data = await response.json();
        setFormData({
        supportNeeded: data.supportNeeded,
        individuals: data.individuals.map(individual => {
            const isOthers = individual.purpose !== 'Assessment' && individual.purpose !== 'Meeting';
            return {
            name: individual.name || '',
            type: individual.type || '',
            project: individual.project || '',
            vertical: individual.vertical || '',
            purpose: isOthers ? 'Others' : individual.purpose,
            otherPurpose: isOthers ? individual.purpose : '',
            };
        }),
        source: data.source || '',
        destination: data.destination || '',
        fromDate: data.fromDate ? data.fromDate.split('T')[0] : '',
        toDate: data.toDate ? data.toDate.split('T')[0] : '',
        batchId: data.batchId || '',
        center: data.center || '',
        client: data.client || '',
        costCenter: data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? 'Others' : data.costCenter,
        otherCostCenter:data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? data.costCenter : '',
        approvedFrom: data.approvedFrom || '',
        approvedDate: data.approvedDate ? data.approvedDate.split('T')[0] : '',
        approvedProof: data.approvedProof || null,
        email: data.email,
        userId:data.userId,
        });
      } catch (error) {
        console.error('Error fetching request data:', error);
      }
    };
  
    fetchData(); // Call fetchData function
  
  }, [requestId, token, user]);

  // Handle input changes
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;
    
    if (name === 'fromDate') {
      // Validate fromDate (should be today or later)
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      if (value < today) {
        notifyError(NotifyMessages.fromDateError,'bottom-center');
        return;
      }
    } else if (name === 'toDate') {
      // Validate toDate (should not be before fromDate)
      if (formData.fromDate && value < formData.fromDate) {
        notifyError(NotifyMessages.toDateError,'bottom-center');
        return;
      }
    }
    
    if (index !== null) {
      const updatedIndividuals = formData.individuals.map((individual, i) => {
        if (i === index) {
          const updatedIndividual = { ...individual, [name]: value };
          if (name === 'purpose' && value === 'Meeting') {
            updatedIndividual.project = 'Office Meeting';
            updatedIndividual.vertical = 'Office Meeting';
          }
          return updatedIndividual;
        }
        return individual;
      });
      setFormData({ ...formData, individuals: updatedIndividuals });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Add a new individual to the individuals array
  const addIndividual = () => {
    setFormData({
      ...formData,
      individuals: [...formData.individuals, { name: '', type: '', project: '', vertical: '', purpose: '', otherPurpose: '' }],
    });
  };

  // Remove an individual from the individuals array
  const removeIndividual = (index) => {
    const updatedIndividuals = formData.individuals.filter((_, i) => i !== index);
    setFormData({ ...formData, individuals: updatedIndividuals });
  };

  //upload file
  const handleFileUpload = async (event, requestId) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {

      const formData = new FormData();
      formData.append('file', uploadedFile);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/approval-details/upload/${requestId}`, {
          method:'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body:formData,
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
        }
  
        notifySuccess(NotifyMessages.uploadSuccess, 'bottom-center');
        const data = await response.json();
        setFormData({
            supportNeeded: data.supportNeeded,
            individuals: data.individuals.map(individual => {
              const isOthers = individual.purpose !== 'Assessment' && individual.purpose !== 'Meeting';
              return {
                name: individual.name || '',
                type: individual.type || '',
                project: individual.project || '',
                vertical: individual.vertical || '',
                purpose: isOthers ? 'Others' : individual.purpose,
                otherPurpose: isOthers ? individual.purpose : '',
              };
            }),
            source: data.source || '',
            destination: data.destination || '',
            fromDate: data.fromDate ? data.fromDate.split('T')[0] : '',
            toDate: data.toDate ? data.toDate.split('T')[0] : '',
            batchId: data.batchId || '',
            center: data.center || '',
            client: data.client || '',
            costCenter: data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? 'Others' : data.costCenter,
            otherCostCenter:data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? data.costCenter : '',
            approvedFrom: data.approvedFrom || '',
            approvedDate: data.approvedDate ? data.approvedDate.split('T')[0] : '',
            approvedProof: data.approvedProof || null,
            email: data.email,
            userId:data.userId,
          });
      } catch (error) {
        console.error(`Error updating booking details for request ${requestId}:`, error);
      }
    }
  };
 //delete file
  const handleFileDelete = async(requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/approval-details/delete/${requestId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      notifyError(NotifyMessages.deleteSuccess, 'bottom-center');
      const data = await response.json();
      setFormData({
        supportNeeded: data.supportNeeded,
        individuals: data.individuals.map(individual => {
          const isOthers = individual.purpose !== 'Assessment' && individual.purpose !== 'Meeting';
          return {
            name: individual.name || '',
            type: individual.type || '',
            project: individual.project || '',
            vertical: individual.vertical || '',
            purpose: isOthers ? 'Others' : individual.purpose,
            otherPurpose: isOthers ? individual.purpose : '',
          };
        }),
        source: data.source || '',
        destination: data.destination || '',
        fromDate: data.fromDate ? data.fromDate.split('T')[0] : '',
        toDate: data.toDate ? data.toDate.split('T')[0] : '',
        batchId: data.batchId || '',
        center: data.center || '',
        client: data.client || '',
        costCenter: data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? 'Others' : data.costCenter,
        otherCostCenter:data.costCenter!=='Govt-Central'&&data.costCenter!=='Govt-State'&&data.costCenter!=='Govt-Others' ? data.costCenter : '',
        approvedFrom: data.approvedFrom || '',
        approvedDate: data.approvedDate ? data.approvedDate.split('T')[0] : '',
        approvedProof: data.approvedProof || null,
        email: data.email,
        userId:data.userId,
      });
    } catch (error) {
      console.error(`Error deleting booking details for request ${requestId}:`, error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    if (formData.fromDate < today) {
        notifyError(NotifyMessages.fromDateError,'bottom-center');
        return; 
    }
    // Validate toDate (should not be before fromDate)
    if (formData.toDate && formData.fromDate && formData.toDate < formData.fromDate) {
        notifyError(NotifyMessages.toDateError,'bottom-center');
        return;
    }
      
    try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
        method:'PUT',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
    }

    notifySuccess(NotifyMessages.requestReopenSuccess, 'bottom-center');
    setTimeout(() => {
        navigate('/'); // Navigate to home or appropriate page after success
    }, 2000);
    } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    }
  };

  // Conditional rendering based on supportNeeded
  const renderFieldsBasedOnSupport = () => {
    switch (formData.supportNeeded) {
      case 'Travel':
        return (
          <>
            <fieldset>
              <legend>Reopen Request:</legend>
              <label>
                Source:
                <input
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Destination:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Date of Journey:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </fieldset>
          </>
        );
      case 'Accommodation':
        return (
          <>
            <fieldset>
              <legend>Route Details:</legend>
              <label>
                Location:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                From Date:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                To Date:
                <input
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </fieldset>
          </>
        );
      case 'Travel + Accommodation':
        return (
          <>
            <fieldset>
              <legend>Route Details:</legend>
              <label>
                Source:
                <input
                  type="text"
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Destination:
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                From Date:
                <input
                  type="date"
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                To Date:
                <input
                  type="date"
                  name="toDate"
                  value={formData.toDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </fieldset>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Request">
      <Header />
      <ToastContainer />
      <main className="main-content">
        <form className="reqForm" onSubmit={handleSubmit}>
          <h1>{requestId ? 'Edit Request' : 'Request Details'}</h1>

          <fieldset>
            <legend>Requested For:</legend>
            <label>
              Support Needed:
              <select
                name="supportNeeded"
                value={formData.supportNeeded}
                onChange={handleInputChange}
                required
              >
                <option value="">Select the support needed</option>
                <option value="Travel">Travel</option>
                <option value="Accommodation">Accommodation</option>
                <option value="Travel + Accommodation">Travel + Accommodation</option>
              </select>
            </label>
            <label>
              Batch ID:
              <input
                type="text"
                name="batchId"
                value={formData.batchId}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Center:
              <input
                type="text"
                name="center"
                value={formData.center}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Client:
              <input
                type="text"
                name="client"
                value={formData.client}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Cost Center:
              <select
                name="costCenter"
                value={formData.costCenter}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Cost-Center</option>
                <option value="Govt-Central">Govt-Central</option>
                <option value="Govt-State">Govt-State</option>
                <option value="Govt-Others">Govt-Others</option>
                <option value="Others">Others</option>
              </select>
            </label>
            {formData.costCenter === 'Others' && (
              <label>
                Mention Cost-Center if selected Others:
                <input
                  type="text"
                  name="otherCostCenter"
                  value={formData.otherCostCenter}
                  onChange={handleInputChange}
                  required
                />
              </label>
            )}
            {formData.individuals.map((individual, index) => (
              <div key={index} className="individual">
                <h3>Individual {index + 1}</h3>
                <label>
                  Individual Name:
                  <input
                    type="text"
                    name="name"
                    value={individual.name}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  />
                </label>
                <label>
                  Individual Type:
                  <select
                    name="type"
                    value={individual.type}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <option value="">Select Individual type</option>
                    <option value="Assessor">Assessor</option>
                    <option value="Proctor">Proctor</option>
                    <option value="SME">SME</option>
                    <option value="Employee">Employee</option>
                    <option value="Employee_Assessor">Employee-Assessor</option>
                    <option value="Trainer">Trainer</option>
                    <option value="Counselor">Counselor</option>
                    <option value="Intern">Intern</option>
                  </select>
                </label>
                <label>
                  Purpose:
                  <select
                    name="purpose"
                    value={individual.purpose}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <option value="">Select Purpose</option>
                    <option value="Assessment">Assessment</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Others">Others</option>
                  </select>
                </label>
                {individual.purpose === 'Others' && (
                  <label>
                    Mention Purpose if selected Others:
                    <input
                      type="text"
                      name="otherPurpose"
                      value={individual.otherPurpose}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </label>
                )}
                {(individual.purpose === 'Assessment' || individual.purpose === 'Others') && (
                    <>
                      <label>
                        Project:
                        <input
                          type="text"
                          name="project"
                          value={individual.project}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </label>
                      <label>
                        Vertical:
                        <input
                          type="text"
                          name="vertical"
                          value={individual.vertical}
                          onChange={(e) => handleInputChange(e, index)}
                          required
                        />
                      </label>
                    </>
                )}
                <button
                  type="button"
                  onClick={() => removeIndividual(index)}
                  className="delete-button"
                >
                  Delete
                </button>
              </div>
            ))}
            <button type="button" onClick={addIndividual} className="add-button">
              Add Individual
            </button>
          </fieldset>

          <fieldset>
            <legend>Approval Details:</legend>
            <label>
              Approved From:
              <input
                type="text"
                name="approvedFrom"
                value={formData.approvedFrom}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Approved Date:
              <input
                type="date"
                name="approvedDate"
                value={formData.approvedDate}
                onChange={handleInputChange}
                required
              />
            </label>
            <div className='upload'>
                <label htmlFor={`fileUpload_${requestId}`} className="upload-button">
                <strong>Approval Proof:</strong> {!formData.approvedProof && <FaCloudUploadAlt size={20} />} 
                <span>{formData.approvedProof ? 
                    <span className='view-download'>
                    <a href={formData.approvedProof.split('$')[1]} target='_blank' rel="noopener noreferrer">
                    {formData.approvedProof.split('$')[0]}
                    </a>
                    </span> 
                : <p className='upload-icon'>Upload</p>}</span>
                <input
                    name="file"
                    type="file"
                    onChange={(e) => handleFileUpload(e,requestId)}
                    id={`fileUpload_${requestId}`}
                    required={!formData.approvedProof}
                />
                </label>
                {formData.approvedProof && 
                    <span className='delete-upload'>
                    <MdDelete onClick={() => handleFileDelete(requestId)}/>
                    </span>
                }
            </div> 
            
          </fieldset>

          {renderFieldsBasedOnSupport()}

          <button type="submit" className='submit-button'>
            Submit
          </button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default ReopenRequest;
