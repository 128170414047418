import React, { useContext, useEffect } from 'react'
import { Outlet, Navigate, useNavigate} from 'react-router-dom';
import { UserContext } from '../UserContext';
import Cookies from 'universal-cookie';
import { jwtDecode } from "jwt-decode"; // Import jwt-decode

const cookies = new Cookies();

export default function Protected() {
    const {user,setUser, token, setToken, setRefreshToken, forceRefreshToken}=useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
      const refresh = async () => {
        try {
          await forceRefreshToken();
        } catch (error) {
          console.error(error);
          setUser(null);
          setToken('');
          setRefreshToken('');

          // Clear user and token from cookies
          cookies.remove('user');
          cookies.remove('token');
          cookies.remove('refreshToken');

          navigate('/login');
        }
      };
  
      if (user && token) {
        const tokenExp = new Date(jwtDecode(token).exp * 1000);
        const now = new Date();
  
        // Refresh token if it's about to expire
        if (tokenExp - now < 60 * 1000) {
          refresh();
        }
      }
    }, [user, forceRefreshToken]);

    //Only allow the user further if token exists
  return (
    token ? <Outlet/> : <Navigate to='/login' />
  )
}
