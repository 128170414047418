export const NotifyMessages = {

  //alert message for register page
  invalidEmail: 'Invalid Email address',
  passwordsNotMatching: 'Passwords not matching',
  weakPassword: 'Password should be at least 8 characters and contain at least one uppercase letter, one number, and one special character',
  registrationSuccessful: 'Admin created!',
  emailAlreadyInUse: 'Email already in use',
  invalidEmailAddress: 'Invalid email address',
  operationNotAllowed: 'Operation not allowed',
  weakPasswordError: 'Password should be at least 8 characters',
  registrationSuccess: 'Registration successful',
  registrationError: 'Registration failed. Please try again',

  //alert message for login page
  loginSuccess: 'Login successful',
  loginError: 'Incorrect email/password',
  googleSignInError: 'Error during Google sign-in',
  enterEmail: 'Please enter your email address',
  passwordResetSent: 'Password reset email sent',
  passwordResetError: 'Error sending password reset email',

  //alert message for profile page
  profileCreationSuccess: 'Profile created successfully',
  profileCreationFailed: 'Profile creation failed',

  //alert message for view-requests page(home page)
  requestDeleted: 'Request deleted',
  requestAccepted: 'Request Accepted',
  requestDenied: 'Request Denied',
  bookingStatusSuccess: 'Booking status updated to Success',
  bookingStatusFail: 'Booking status updated to Fail',
  remarksSent: 'Remarks sent successfully',

  //alert message for planner page
  selectOriginDestination: 'Please Select Origin/Destination',
  dateValidation: 'Arrival date cannot be in the past',
  noRoutesFound: 'No route could be found between the origin and destination',
  selectLocation: 'Please Select Location',
  checkinValidation: 'Enter valid check-in and check-out date',
  adultValidation: 'At least one adult and one room needed',
  roomValidation: 'Rooms should be equal to or less than adults',
  sortBySelection: 'Select Sort By',

  //alert message for update-booking details page
  fromDateError: 'From Date must be today or later',
  toDateError: 'To Date cannot be before From Date',
  requestReopenSuccess: 'Request reopened successfully',
  requestEditSuccess: 'Request edited successfully',
  requestSubmitSuccess: 'Request submitted successfully',
  uploadSuccess: 'Uploaded successfully',
  deleteSuccess: 'Deleted successfully',
  bookingSuccess: 'Booking-Details submitted successfully',

  //alert message for cancel-request page
  cancelReasonRequired: 'Cancel Reason is required',
  cancelDetailsSubmitted: 'Cancel-Details submitted successfully',

  //alert message for review page
  reviewRequired: 'Remarks are required',
  reviewSubmitted: 'Thanks for submitting your review',

};
