import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';  // Importing UserContext for managing user state
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";  // Firebase authentication methods
import { auth } from '../components/firebaseAuth';  // Firebase authentication instance
import './styles/Register.css';  // Styles for the Register component
import GetIP from '../components/GetIP';  // Component to get user's IP address
import title from '../assets/title.png';  // Title image
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed

// Function to get browser details
const getBrowserDetails = () => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.userAgentData?.platform,
    language: navigator.language,
    vendor: navigator.vendor,
  };
};

export default function Register() {
  const [email, setEmail] = useState('');  // State for user email input
  const [password, setPassword] = useState('');  // State for user password input
  const [confirmPassword, setConfirmPassword] = useState('');  // State for confirming password input
  const {user, setUser, setToken, setRefreshToken } = useContext(UserContext);  // Using context to set user state
  const [passwordVisible, setPasswordVisible] = useState(false);  // State to toggle password visibility
  const [ip, setIp] = useState('');  // State to store user's IP address
  const navigate = useNavigate();  // Navigation hook for redirecting after actions

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to handle form submission for user registration
  const handleClick = async (event) => {
    event.preventDefault();
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Validations
    if (!email.endsWith('@gmail.com') && !email.endsWith('@navriti.com')) {
      notifyError(NotifyMessages.invalidEmail,'top-right');
      return;
    }
    if (password !== confirmPassword) {
      notifyError(NotifyMessages.passwordsNotMatching,'top-right');
      return;
    }
    if (!passwordPattern.test(password)) {
      notifyError(NotifyMessages.weakPassword,'top-right');
      return;
    }

    try {
      // Create user with email and password using Firebase auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const currUser = userCredential.user;

      const accessToken = await currUser.getIdToken(/* forceRefresh */ true);
      // API request to store user data in the database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`  // Send token for backend verification
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      // Handle successful registration
      if (response.ok) {
        notifySuccess(NotifyMessages.registrationSuccess,'top-right');

        setTimeout(() => {
          navigate('/login');  // Redirect to login page after 2 seconds
        }, 2000);
      } else {
        // Registration failed, handle error
        console.error('Registration failed');
      }
    } catch (error) {
      // Handle Firebase authentication errors
      switch (error.code) {
        case 'auth/email-already-in-use':
          notifyError(NotifyMessages.emailAlreadyInUse,'top-right');
          break;
        case 'auth/invalid-email':
          notifyError(NotifyMessages.invalidEmailAddress,'top-right');
          break;
        case 'auth/operation-not-allowed':
          notifyError(NotifyMessages.operationNotAllowed,'top-right');
          break;
        case 'auth/weak-password':
          notifyError(NotifyMessages.weakPasswordError,'top-right');
          break;
        default:
          notifyError(NotifyMessages.registrationError,'top-right');
          console.error('Registration failed:', error);
          break;
      }
    }
  };

  // Function to handle Google sign-in
  const handleSignIn = async (event) => {
    event.preventDefault();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    try {
      // Get browser details
      const browserDetails = getBrowserDetails();

      // Sign in with Google popup using Firebase auth
      const userCredential = await signInWithPopup(auth, provider);
      const currUser = userCredential.user;
      // Get access token
      const accessToken = await currUser.getIdToken(/* forceRefresh */ true);

      // Send Google sign-in data to backend API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          email: currUser.email,
          browserDetails: browserDetails,
          ipAddress: ip,
        }),
      });

      // Handle successful Google sign-in
      if (response.ok) {
        notifySuccess(NotifyMessages.loginSuccess, 'top-right');
        const data = await response.json();
        const {accessToken, refreshToken, ...newData}=data;

        setTimeout(() => {
          setUser(newData);  // Set user data in context
          setToken(data.accessToken);  // Set access token in context
          setRefreshToken(data.refreshToken);
          navigate('/profile');  // Redirect to profile page after 2 seconds
        }, 2000);
      } else {
        // Handle Google sign-in failure
        notifyError(NotifyMessages.loginError, 'top-right');
        console.error('Invalid credentials');
      }
    } catch (error) {
      // Handle Google sign-in error
      notifyError(NotifyMessages.googleSignInError, 'top-right');
      console.error('Error during login:', error);
    }
  };

  return (
    <div className='container forms show-signup'>
      <div className="form signup">
        <ToastContainer />  {/* Toast notification container */}
        <GetIP setIp={setIp} />  {/* Component to get user's IP address */}
        <div className="form-content">
          <img src={title} alt='title' />  {/* Title image */}
          <form onSubmit={handleClick}>
            <div className="field input-field">
              <input 
                type="email" 
                placeholder="Email" 
                className="input" 
                onChange={(event) => setEmail(event.target.value)} 
                required 
                autoComplete="username"
              />
            </div>
            <div className="field input-field">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Create password"
                className="password"
                onChange={(event) => setPassword(event.target.value)}
                required
                autoComplete="current-password"
              />
            </div>
            <div className="field input-field">
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                className="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
                autoComplete="current-password"
              />
              <i
                className={`bx ${passwordVisible ? 'bx-show' : 'bx-hide'} eye-icon`}
                onClick={handleTogglePasswordVisibility}
              ></i>
            </div>
            <div className="field button-field">
              <button>Signup</button>  {/* Signup button */}
            </div>
          </form>
          <div className="form-link">
            <span>
              Already have an account?{' '}
              <Link to='/login' className="link login-link">
                Login
              </Link>
            </span>
          </div>
        </div>
        <div className="line"></div>  {/* Divider line */}
        <div className="media-options">
          <button onClick={handleSignIn} className="field google">
            <img src="https://cdn.icon-icons.com/icons2/2224/PNG/512/google_logo_icon_134448.png" alt="" className="google-img" />
            <span>Login with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
}
