import { useState, useEffect } from "react";

export default function GetIP({ setIp }) {
  useEffect(() => {
    const getIp = async () => {
      try {
        //API for fetching IP Address
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    };

    getIp();
  }, [setIp]);

  return null; // This component doesn't render anything itself
}
