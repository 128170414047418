import React, { useState, useContext, useEffect } from 'react';
import './styles/Request.css'; // Importing custom CSS for styling
import Header from '../components/Header'; // Importing Header component
import Footer from '../components/Footer'; // Importing Footer component
import { useNavigate, useParams } from 'react-router-dom'; // Importing navigation hook
import { UserContext } from '../UserContext'; // Importing UserContext to get user information
import {FaCloudUploadAlt} from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import {notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed

function UpdateBooking() {
  const { user, token } = useContext(UserContext); // Getting user and token from UserContext
  const navigate = useNavigate(); // Hook for navigation
  const { requestId } = useParams(); // Get request ID from URL params
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [file, setFile] = useState(null); // Track file upload state
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  // Initial form state
  const [status, setStatus] = useState('');
  const [supportNeeded, setSupportNeeded] = useState('');
  const [formData, setFormData] = useState({
    bookingDate: '',
    bookingBy: '',
    amount: '',
    pnrNumber:'',
    fileUrl:'',
    fileName:'',
    refundAmount:'',
    lostAmount:'',
    podNumber: '',
    courierCo: '',
  });

  // Effect to fetch request data if editing an existing request
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing request data for editing
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch request data');
        }

        const requestData = await response.json();
        setSupportNeeded(requestData.supportNeeded);
        setStatus(requestData.status);
        const data = requestData.bookingDetails; 
        
        setFormData({
          bookingDate:data.bookingDate ? data.bookingDate.split('T')[0] : formData.bookingDate.split('T')[0] || '' ,
          bookingBy: data.bookingBy || formData.bookingBy || '',
          amount: data.amount || formData.amount || '',
          pnrNumber:data.pnrNumber || formData.pnrNumber || '',
          fileUrl:data.fileUrl || formData.fileUrl || '',
          fileName:data.fileName || formData.fileName || '',
          refundAmount:data.refundAmount || formData.refundAmount || '',
          lostAmount:data.lostAmount || formData.lostAmount || '',
          podNumber:data.podNumber || formData.podNumber || '',
          courierCo:data.courierCo || formData.courierCo || '',
        });
        
        setName((prev) => data.fileName || prev);
        setUrl((prev) => data.fileUrl || prev);
      } catch (error) {
        console.error('Error fetching request data:', error);
      }
    };
  
    fetchData(); // Call fetchData function
  
  }, [requestId, token, user]);


  // Effect to automatically calculate and format lostAmount
  useEffect(() => {
    if(status === 'Cancelled'){
      const amount = parseFloat(formData.amount) || 0;
      const refundAmount = parseFloat(formData.refundAmount) || 0;
      let lostAmount = amount - refundAmount;

      // Format lostAmount to remove unnecessary decimal places
      lostAmount = lostAmount.toFixed(2);
      if (lostAmount.endsWith('.00')) {
        lostAmount = lostAmount.slice(0, -3); // Remove '.00' if present
      }

      setFormData(prevData => ({
        ...prevData,
        lostAmount
      }));
    }
    else{
      setFormData(prevData => ({
        ...prevData,
        lostAmount:0,
      }));
    }
  }, [formData.amount, formData.refundAmount]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile); // Store the file in state
      setName(uploadedFile.name);
      const temp=URL.createObjectURL(uploadedFile);
      setUrl(temp);
    }
  };

  const handleFileDelete = async() => {
    setFile(null);
    setName('');
    setUrl('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/booking-details/${requestId}`, {
        method:'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      notifySuccess(NotifyMessages.bookingSuccess, 'bottom-center');
      setFormSubmitted(true); // Mark form as submitted
      setTimeout(() => {
        navigate('/feedback'); // Navigate to home or appropriate page after success
      }, 2000);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (formSubmitted && file) {
      const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/booking-details/upload/${requestId}`, {
            method:'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body:formData,
          });
    
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
          }

          const data = await response.json();
          setFormData({
            bookingDate: data.bookingDate ? data.bookingDate.split('T')[0] : '',
            bookingBy: data.bookingBy || '',
            amount: data.amount || '',
            pnrNumber:data.pnrNumber || '',
            fileUrl:data.fileUrl || '',
            fileName:data.fileName || '',
            refundAmount:data.refundAmount || '',
            lostAmount:data.lostAmount || '',
            podNumber:data.podNumber || '',
            courierCo:data.courierCo || '',
          });
          setName(data.fileName || '');
          setUrl(data.fileUrl || '');
        } catch (error) {
          console.error(`Error updating booking details for request ${requestId}:`, error);
        }
      };
      uploadFile(); // Upload the file only after form submission
    }
  }, [formSubmitted, file, requestId, token]);

  return (
    <div className="Request">
      <Header />
      <ToastContainer />
      <main className="main-content">
        <form className="reqForm" onSubmit={handleSubmit}>
          <h1>Book a New Entry</h1>

          <fieldset>
            <legend>Booking Details:</legend>
            <label>
              Booking Date:
              <input
                type="date"
                name="bookingDate"
                value={formData.bookingDate}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Booking By:
              <input
                type="text"
                name="bookingBy"
                value={formData.bookingBy}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Amount(In Rs):
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                required
              />
            </label>
            {supportNeeded.includes('Courier') ? 
            <>
            <label>
              Proof of Delivery(POD) Number:
              <input
                type="text"
                name="podNumber"
                value={formData.podNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Courier Company:
              <input
                type="text"
                name="courierCo"
                value={formData.courierCo}
                onChange={handleInputChange}
                required
              />
            </label>
            </>
            :
            <label>
              PNR Number:
              <input
                type="text"
                name="pnrNumber"
                value={formData.pnrNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            }
            <div className='upload'>
                <label htmlFor={`fileUpload_${requestId}`} className="upload-button">
                <strong>Booking Details:</strong> {!name && !url && <FaCloudUploadAlt size={20} />} 
                <span>{name && url ? 
                    <span className='view-download'>
                    <a href={url} target='_blank' rel="noopener noreferrer">
                        {name}
                    </a>
                    </span> 
                : <p className='upload-icon'>Upload</p>}</span>
                <input
                    name="file"
                    type="file"
                    onChange={(e) => handleFileUpload(e,requestId)}
                    id={`fileUpload_${requestId}`}
                    required={!name && !url}
                />
                </label>
                {name && url && 
                    <span className='delete-upload'>
                    <MdDelete onClick={handleFileDelete}/>
                    </span>
                }
            </div>
            {status === 'Cancelled' &&
            <>
            <label>
              Refund Amount(In Rs):
              <input
                type="number"
                name="refundAmount"
                value={formData.refundAmount}
                onChange={handleInputChange}
                required
              />
            </label>

            <label>
              Lost Amount(In Rs):
              <input
                type="number"
                name="lostAmount"
                value={formData.lostAmount}
                readOnly
              />
            </label>
            </>
          }
          </fieldset>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default UpdateBooking;
