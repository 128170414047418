// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 .tabs-container {
    width: 100%;
    margin:0;
  }
  
  .tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
    width:100%;
  }
  
  .tab {
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-bottom: none;
    background-color: #fff;
    transition: background-color 0.3s;
  }
  

  .tab.active {
    background-color: #ddd;
  }
  
  .tab-content {
    border: 2px solid #ddd;
    padding: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Tabs/Tabs.css"],"names":[],"mappings":";CACC;IACG,WAAW;IACX,QAAQ;EACV;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,iCAAiC;EACnC;;;EAGA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;IACtB,aAAa;EACf","sourcesContent":["\n .tabs-container {\n    width: 100%;\n    margin:0;\n  }\n  \n  .tabs {\n    display: flex;\n    border-bottom: 1px solid #ddd;\n    width:100%;\n  }\n  \n  .tab {\n    cursor: pointer;\n    padding: 10px;\n    margin-right: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    border-bottom: none;\n    background-color: #fff;\n    transition: background-color 0.3s;\n  }\n  \n\n  .tab.active {\n    background-color: #ddd;\n  }\n  \n  .tab-content {\n    border: 2px solid #ddd;\n    padding: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
