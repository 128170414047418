// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "my-project-e9ffb.firebaseapp.com",
  projectId: "my-project-e9ffb",
  storageBucket: "my-project-e9ffb.appspot.com",
  messagingSenderId: "478207376877",
  appId: "1:478207376877:web:f016665c6b74c95d3d3be4",
  measurementId: "G-THHPRVZM7V"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth=getAuth(app)
//const analytics = getAnalytics(app);