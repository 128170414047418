import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles/Report.css';
import { UserContext } from '../UserContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import moment from 'moment';
import { FaDownload } from "react-icons/fa";
import { FaDeleteLeft } from "react-icons/fa6";
import { DownloadTableExcel} from 'react-export-table-to-excel';
import TableToExcel from "@linways/table-to-excel";
import { useNavigate } from 'react-router-dom';
import { VscSettings } from "react-icons/vsc";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Tabs from '../components/Tabs/Tabs';

const formatDate = (date) => {
  const temp = date.split('T')[0];
  return moment(temp).format('DD-MM-YYYY');
};

const Report = () => {
  //states for storing the data for the table
  var courierIndex = 1;
  var travelIndex = 1;
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const navigate=useNavigate();
  const { user, token } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    requests: ['All'],
    requesterType:['All'],
    supportType: ['All'],
    status: ['All'],
    purpose: ['All'],
    costCenter: ['All'],
    requestStatus: ['All'],
    bookingStatus: ['All'],
  });
  const [dropdownVisible, setDropdownVisible] = useState({
    requests: false,
    requesterType:false,
    supportType: false,
    status: false,
    purpose: false,
    costCenter: false,
    requestStatus: false,
    bookingStatus: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchHeader, setSearchHeader] = useState('requesterName');
  const [showFilters, setShowFilters] = useState(false); // State to control filter visibility
  const [dateRange, setDateRange] = useState({ start: null, end: null });

  useEffect(() => {
    if (user && user.role !== 'USER') {
      fetchAllRequests();
    }
  }, [user, token]); // Trigger fetch on user change

  useEffect(() => {
    filterRequests();
  }, [data, filterOptions, searchTerm, dateRange]); // Re-filter when data or filter options change

  const fetchAllRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 401) {
          // Handle token expiration or unauthorized access
          navigate('/login');
        }
        throw new Error(errorData.error || 'Network response was not ok.');
      }

      const requestData = await response.json();

      // Fetch user profile for each request
       const requestsWithProfiles = await Promise.all(
        requestData.map(async (req) => {
          const profileResponse = await fetch(`${process.env.REACT_APP_API_URL}/profile/${req.userId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (!profileResponse.ok) {
            throw new Error(`Failed to fetch profile for user ${req.userId}`);
          }

          const profileData = await profileResponse.json();
          if(!req.supportNeeded.includes('Courier')){
          return {
            id: req.id,
            userId: req.userId,
            requesterName: profileData.name,
            requesterType: profileData.type,
            supportNeeded: req.supportNeeded,
            location: req.supportNeeded === 'Accommodation' ? req.destination : `${req.source} to ${req.destination}`,
            date: req.supportNeeded === 'Travel' ? formatDate(req.fromDate) : `${formatDate(req.fromDate)} to ${formatDate(req.toDate)}`,
            totalCount: req.individuals.length,
            status: req.status,
            bookingStatus: req.bookingDetails.bookingStatus,
            requestDate: formatDate(req.createdAt),
            batchId: req.batchId,
            amount:req.bookingDetails.amount ? req.bookingDetails.amount : 'NA',
            bookingBy:req.bookingDetails.bookingBy ? req.bookingDetails.bookingBy : 'NA',
            bookingDate:req.bookingDetails.bookingDate ? formatDate(req.bookingDetails.bookingDate) : 'NA',
            pnrNumber:req.bookingDetails.pnrNumber ? req.bookingDetails.pnrNumber : 'NA',
            passengersName:req.individuals,
            approvedFrom:req.approvedFrom,
            approvedDate:formatDate(req.approvedDate),
            approvedProof:req.approvedProof.split('$')[1],
            center:req.center,
            client:req.client,
            costCenter:req.costCenter,
            bookingDetails:req.bookingDetails.fileUrl ? req.bookingDetails.fileUrl : '',
            remarks:req.remarks ? req.remarks : 'NA',
            cancelReason:req.bookingDetails.cancelReason ? req.bookingDetails.cancelReason : 'NA',
            cancelProof:req.bookingDetails.cancelProof ? req.bookingDetails.cancelProof.split('$')[1] : '',
            refundAmount:req.bookingDetails.refundAmount || 'NA',
            lostAmount:req.bookingDetails.lostAmount || 'NA',
          };
        }else if(req.supportNeeded.includes('Courier')){
            return {
              id: req.id,
              userId: req.userId,
              requesterName: profileData.name,
              requesterType: profileData.type,
              requestDate: formatDate(req.createdAt),
              supportNeeded: req.supportNeeded,
              courierType: req.courierType,
              officeLocation: req.officeLocation,
              fromLocation: req.fromLocation || 'NA',
              toLocation: req.toLocation || 'NA',
              sentFrom: req.sentFrom || 'NA',
              receivedBy: req.receivedBy || 'NA',
              receivedStatus: req.receivedStatus || 'No',
              receivedOn: req.receivedOn ? formatDate(req.receivedOn) : 'NA',
              status: req.status,
              bookingStatus: req.bookingDetails.bookingStatus || 'NA',
              amount:req.bookingDetails.amount ? req.bookingDetails.amount : 'NA',
              bookingBy:req.bookingDetails.bookingBy ? req.bookingDetails.bookingBy : 'NA',
              bookingDate:req.bookingDetails.bookingDate ? formatDate(req.bookingDetails.bookingDate) : 'NA',
              podNumber:req.bookingDetails.podNumber ? req.bookingDetails.podNumber : 'NA',
              courierCo:req.bookingDetails.courierCo ? req.bookingDetails.courierCo : 'NA',
              bookingDetails:req.bookingDetails.fileUrl ? req.bookingDetails.fileUrl : '',
              remarks:req.remarks ? req.remarks : 'NA',
              cancelReason:req.bookingDetails.cancelReason ? req.bookingDetails.cancelReason : 'NA',
              cancelProof:req.bookingDetails.cancelProof ? req.bookingDetails.cancelProof.split('$')[1] : '',
              refundAmount:req.bookingDetails.refundAmount || 'NA',
              lostAmount:req.bookingDetails.lostAmount || 'NA',
            };}
        })
      );
      console.log(requestsWithProfiles);
      setData(requestsWithProfiles);
    } catch (error) {
      // Handle other errors
      console.error('Error fetching all requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterRequests = () => {
    let filtered = data;
    const currentDate = moment().startOf('day');

    // Apply search filter
    if (searchTerm && !['requestDate', 'approvedDate', 'bookingDate', 'date', 'receivedOn'].includes(searchHeader)) {
      filtered = filtered.filter(req => {
          if (searchHeader === 'passengerName') {
              return req.passengersName.some(passenger => passenger.name.toLowerCase().includes(searchTerm.toLowerCase()));
          } else if (searchHeader === 'requestId') {
              return req.id.toString().toLowerCase().includes(searchTerm.toLowerCase());
          }
          return req[searchHeader]?.toLowerCase().includes(searchTerm.toLowerCase());
      });
  }

  // Apply date range filter for date fields
  if (['requestDate', 'approvedDate', 'bookingDate', 'date', 'receivedOn'].includes(searchHeader) && dateRange.start && dateRange.end) {
      const startDate = moment(dateRange.start).startOf('day');
      const endDate = moment(dateRange.end).endOf('day');

      filtered = filtered.filter(req => {
          let dateToCompare;
          switch (searchHeader) {
              case 'requestDate':
                  dateToCompare = moment(req.requestDate, 'DD-MM-YYYY');
                  break;
              case 'approvedDate':
                  dateToCompare = moment(req.approvedDate, 'DD-MM-YYYY');
                  break;
              case 'bookingDate':
                  dateToCompare = moment(req.bookingDate, 'DD-MM-YYYY');
                  break;
              case 'date':
                if (req.supportNeeded === 'Travel') {
                    dateToCompare = moment(req.date.split('-').reverse().join('-'), 'YYYY-MM-DD');
                    return dateToCompare.isBetween(startDate, endDate, null, '[]');
                } else {
                    // Parse fromDate and toDate
                    const [fromDateStr, toDateStr] = req.date.split(' to ');
                    const fromDate = moment(fromDateStr.split('-').reverse().join('-'), 'YYYY-MM-DD');
                    const toDate = moment(toDateStr.split('-').reverse().join('-'), 'YYYY-MM-DD');

                    // Check if any date between fromDate and toDate is within the range
                    return fromDate.isBetween(startDate, endDate, null, '[]') ||
                        toDate.isBetween(startDate, endDate, null, '[]') ||
                        (fromDate.isBefore(endDate) && toDate.isAfter(startDate));
                }
              case 'receivedOn':
                dateToCompare = moment(req.receivedOn, 'DD-MM-YYYY');
                break;
              default:
                  dateToCompare = moment(); // Default case, should not occur
          }
          return dateToCompare.isBetween(startDate, endDate, null, '[]');
      });
  }

    // Filter by requester (All or My Requests)
    if (!filterOptions.requests.includes('All')) {
      filtered = filtered.filter(req => filterOptions.requests.includes(req.userId === user.id ? 'My Requests' : 'All'));
    }

    // Filter by support type (All, Travel, Accommodation, Travel + Accommodation)
    if (!filterOptions.requesterType.includes('All')) {
      filtered = filtered.filter(req => filterOptions.requesterType.includes(req.requesterType));
    }
    
    // Filter by support type (All, Travel, Accommodation, Travel + Accommodation)
    if (!filterOptions.supportType.includes('All')) {
      filtered = filtered.filter(req => filterOptions.supportType.includes(req.supportNeeded));
    }

    // Filter by status (Open or Closed)
    if (!filterOptions.status.includes('All')) {
      filtered = filtered.filter(req => {
        if (filterOptions.status.includes('Open')) {
          if (req.supportNeeded === 'Travel') {
            let date = req.date.split('-').reverse().join('-');
            return moment(date).isSameOrAfter(currentDate);
          } else {
            let date = req.date.split(' to ')[1].split('-').reverse().join('-');
            return moment(date).isSameOrAfter(currentDate);
          }
        } else if (filterOptions.status.includes('Closed')) {
          if (req.supportNeeded === 'Travel') {
            let date = req.date.split('-').reverse().join('-');
            return moment(date).isBefore(currentDate);
          } else {
            let date = req.date.split(' to ')[1].split('-').reverse().join('-');
            return moment(date).isBefore(currentDate);
          }
        }
        return false;
      });
    }

  // Filter by purpose
  if (!filterOptions.purpose.includes('All')) {
    let stored=[...filtered];
    filtered = filtered.filter(req => 
      req.passengersName.some(passenger => filterOptions.purpose.includes(passenger.purpose))
    );
    if(filterOptions.purpose.includes('Others')){
      stored = stored.filter(req => 
        req.passengersName.some(passenger => !['Assessment','Meeting'].includes(passenger.purpose))
      );
      filtered=[...filtered,...stored];
    }
  }

  // Filter by cost center
  if (!filterOptions.costCenter.includes('All')) {
    let stored=[...filtered];
    filtered = filtered.filter(req => filterOptions.costCenter.includes(req.costCenter));
    if(filterOptions.costCenter.includes('Others')){
      stored = stored.filter(req => filterOptions.costCenter.includes(req.costCenter));
      filtered=[...filtered,...stored];
    }
  }

  // Filter by request status
  if (!filterOptions.requestStatus.includes('All')) {
    filtered = filtered.filter(req => filterOptions.requestStatus.includes(req.status));
  }

  // Filter by booking status
  if (!filterOptions.bookingStatus.includes('All')) {
    filtered = filtered.filter(req => filterOptions.bookingStatus.includes(req.bookingStatus));
  }

    setFilteredData(filtered);
  };

  const handleFilterChange = (filterCategory, value) => {
    setFilterOptions(prevState => {
      const newFilterOptions = { ...prevState };
      if (newFilterOptions[filterCategory].includes(value)) {
        newFilterOptions[filterCategory] = newFilterOptions[filterCategory].filter(item => item !== value);
      } else {
        newFilterOptions[filterCategory].push(value);
      }
      return newFilterOptions;
    });
  };
  const toggleDropdown = (filterCategory) => {
    setDropdownVisible(prevState => {
      const newDropdownVisible = { ...prevState, [filterCategory]: !prevState[filterCategory] };
      Object.keys(newDropdownVisible).forEach(key => {
        if (key !== filterCategory) {
          newDropdownVisible[key] = false;
        }
      });
      return newDropdownVisible;
    });
  };  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchHeaderChange = (event) => {
    setSearchHeader(event.target.value);
  };

  const handleFilterButtonClick = () => {
    setShowFilters(prev => !prev); // Toggle filter visibility
  };
  const handleClearFilters = () => {
    setFilterOptions({
      requests: ['All'],
      requesterType: ['All'],
      supportType: ['All'],
      status: ['All'],
      purpose: ['All'],
      costCenter: ['All'],
      requestStatus: ['All'],
      bookingStatus: ['All'],
    });
    setSearchTerm('');
    setSearchHeader('requesterName');
    setDropdownVisible({
      requests: false,
      requesterType:false,
      supportType: false,
      status: false,
      purpose: false,
      costCenter: false,
      requestStatus: false,
      bookingStatus: false,
    })
  };
  const handleDownload = (title) => {
    TableToExcel.convert(tableRef.current, {
      name: `${title}_Desk_${new Date().toLocaleDateString()}.xlsx`,
      sheet: {
        name: "Report"
      }
    });
  };
   // Data for tabs to switch between Travel and Hotel views
   const tabData = [
    { label: "Travel" },
    { label: "Courier" },
  ];

  return (
    <div className='Report'>
      <Header />
      <Tabs tabs={tabData}>
      <div className="report-page">
        <div className='report-header'>
          <h2>Summary</h2>
          <div className='search-filter'>
          {showFilters && 
            <div className={`filter-options ${showFilters ? 'open-menu' : ''}`}>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requests ? 'open-menu' : ''}`}>
                  {['All', 'My Requests'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requests.includes(option)}
                        onChange={() => handleFilterChange('requests', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requests')}>Requests</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requesterType ? 'open-menu' : ''}`}>
                  { ['All', 'Assessor', 'Proctor', 'SME', 'Employee', 'Employee_Assessor','Trainer', 'Counselor', 'Intern'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requesterType.includes(option)}
                        onChange={() => handleFilterChange('requesterType', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requesterType')}>Requester Type</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.supportType ? 'open-menu' : ''}`}>
                  {['All', 'Travel', 'Accommodation', 'Travel + Accommodation'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.supportType.includes(option)}
                        onChange={() => handleFilterChange('supportType', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('supportType')}>Support Type</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.status ? 'open-menu' : ''}`}>
                  {['All', 'Open', 'Closed'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.status.includes(option)}
                        onChange={() => handleFilterChange('status', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('status')}>Open/Closed</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.purpose ? 'open-menu' : ''}`}>
                  {['All', 'Assessment', 'Meeting', 'Others'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.purpose.includes(option)}
                        onChange={() => handleFilterChange('purpose', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('purpose')}>Purpose</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.costCenter ? 'open-menu' : ''}`}>
                  {['All', 'Govt-Central', 'Govt-State', 'Govt-Others', 'Others'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.costCenter.includes(option)}
                        onChange={() => handleFilterChange('costCenter', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('costCenter')}>Cost Center</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requestStatus ? 'open-menu' : ''}`}>
                  {['All', 'Pending', 'Accepted', 'Denied', 'Cancelled'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requestStatus.includes(option)}
                        onChange={() => handleFilterChange('requestStatus', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requestStatus')}>Request Status</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.bookingStatus ? 'open-menu' : ''}`}>
                  {['All', 'Success', 'Fail', 'Pending', 'Cancelled'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.bookingStatus.includes(option)}
                        onChange={() => handleFilterChange('bookingStatus', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('bookingStatus')}>Booking Status</button>
            </div>
            </div>}
            <div className="search-container">
            <div className="search-fields">
              <label>
                <strong>Search By:</strong>
                <select value={searchHeader} onChange={handleSearchHeaderChange}>
                  <option value="requesterName">Requester Name</option>
                  <option value="requestId">Request ID</option>
                  <option value="requestDate">Request Date</option>
                  <option value="batchId">Batch ID</option>
                  <option value="center">Center</option>
                  <option value="client">Client</option>
                  <option value="location">Location</option>
                  <option value="date">Date of Journey</option>
                  <option value="approvedFrom">Approved From</option>
                  <option value="approvedDate">Approved Date</option>
                  <option value="passengerName">Passenger Name</option>
                  <option value="bookingBy">Booking By</option>
                  <option value="bookingDate">Booking Date</option>
                  <option value="amount">Amount</option>
                  <option value="pnrNumber">PNR Number</option>
                  <option value="remarks">Remarks</option>
                  <option value="cancelReason">Cancel Reason</option>
                  <option value="refundAmount">Refund Amount</option>
                  <option value="lostAmount">Lost Amount</option>
                </select>
              </label>
              {!['requestDate', 'approvedDate', 'bookingDate', 'date'].includes(searchHeader) ? 
                <div className='search-input'>
                  <strong>Search Here:</strong>
                  <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder='Search here...'/>
                </div> :
                <div className='search-input'>
                  <strong>Search Here:</strong>
                  <DatePicker
                    selected={dateRange.start}
                    onChange={(dates) => setDateRange({
                      start: dates[0],
                      end: dates[1]
                    })}
                    startDate={dateRange.start}
                    endDate={dateRange.end}
                    selectsRange
                    className='date-picker'
                  />
                </div>
              }
             </div>
            </div>
            <button className='filter-button' onClick={handleFilterButtonClick}>
              <VscSettings />
              <span><strong>Filter</strong></span>
            </button>
            <button className='filter-button' onClick={handleClearFilters}>
              <FaDeleteLeft />
              <span><strong>Clear</strong></span>
            </button>
          </div>
          <button className='download-button' onClick={() => handleDownload('Travel')}>
            <FaDownload />
            <span>Download</span>
          </button>
        </div>
        <div className="report-table">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th data-f-bold='true'>S.No</th>
                <th data-f-bold='true'>Request Date</th>
                <th data-f-bold='true'>Request ID</th>
                <th data-f-bold='true'>Requester Name</th>
                <th data-f-bold='true'>Requester Type</th>
                <th data-f-bold='true'>Support Needed</th>
                <th data-f-bold='true'>Batch Id</th>
                <th data-f-bold='true'>Location</th>
                <th data-f-bold='true'>Amount (In Rs)</th>
                <th data-f-bold='true'>Booking By</th>
                <th data-f-bold='true'>Booking Date</th>
                <th data-f-bold='true'>Date of Journey</th>
                <th data-f-bold='true'>PNR Number</th>
                <th data-f-bold='true'>Passengers Count</th>
                <th data-f-bold='true'>Passengers Name-Type</th>
                <th data-f-bold='true'>Project</th>
                <th data-f-bold='true'>Vertical</th>
                <th data-f-bold='true'>Purpose</th>
                <th data-f-bold='true'>Approved From</th>
                <th data-f-bold='true'>Approved Date</th>
                <th data-f-bold='true'>Approved Proof</th>
                <th data-f-bold='true'>Center</th>
                <th data-f-bold='true'>Client</th>
                <th data-f-bold='true'>Cost-Center</th>
                <th data-f-bold='true'>Status</th>
                <th data-f-bold='true'>Booking Status</th>
                <th data-f-bold='true'>Booking Details</th>
                <th data-f-bold='true'>Remarks</th>
                <th data-f-bold='true'>Cancel Reason</th>
                <th data-f-bold='true'>Cancel Proof</th>
                <th data-f-bold='true'>Refund Amount (In Rs)</th>
                <th data-f-bold='true'>Lost Amount (In Rs)</th>
              </tr>
            </thead>
            
            <tbody>
              {loading ? <p className='loading'>Loading...</p> :
              filteredData && filteredData.map((request) => (
                <React.Fragment key={request.id}>
                  {request.passengersName && request.passengersName.map((passenger, passengerIndex) => (
                    <React.Fragment key={`${passengerIndex}`}>
                      {passengerIndex === 0 && (
                        <tr key={`${request.id}-${passengerIndex}`}>
                          <td rowSpan={request.passengersName.length}>{travelIndex++}</td>
                          <td rowSpan={request.passengersName.length}>{request.requestDate}</td>
                          <td rowSpan={request.passengersName.length}>{request.id}</td>
                          <td rowSpan={request.passengersName.length}>{request.requesterName}</td>
                          <td rowSpan={request.passengersName.length}>{request.requesterType}</td>
                          <td rowSpan={request.passengersName.length}>{request.supportNeeded}</td>
                          <td rowSpan={request.passengersName.length}>{request.batchId}</td>
                          <td rowSpan={request.passengersName.length}>{request.location}</td>
                          <td rowSpan={request.passengersName.length}>{request.amount}</td>
                          <td rowSpan={request.passengersName.length}>{request.bookingBy}</td>
                          <td rowSpan={request.passengersName.length}>{request.bookingDate}</td>
                          <td rowSpan={request.passengersName.length}>{request.date}</td>
                          <td rowSpan={request.passengersName.length}>{request.pnrNumber}</td>
                          <td rowSpan={request.passengersName.length}>{request.totalCount}</td>
                          <td>{passenger.name}-{passenger.type}</td>
                          <td>{passenger.project}</td>
                          <td>{passenger.vertical}</td>
                          <td>{passenger.purpose}</td>
                          <td rowSpan={request.passengersName.length}>{request.approvedFrom}</td>
                          <td rowSpan={request.passengersName.length}>{request.approvedDate}</td>
                          <td rowSpan={request.passengersName.length} data-hyperlink={request.approvedProof} data-f-color={request.approvedProof?'FF0000FF':''}>
                            <a href={request.approvedProof} target='_blank' rel='noopener noreferrer'>View file</a>
                          </td>
                          <td rowSpan={request.passengersName.length}>{request.center}</td>
                          <td rowSpan={request.passengersName.length}>{request.client}</td>
                          <td rowSpan={request.passengersName.length}>{request.costCenter}</td>
                          <td rowSpan={request.passengersName.length}>{request.status}</td>
                          <td rowSpan={request.passengersName.length}>{request.bookingStatus}</td>
                          <td rowSpan={request.passengersName.length} data-hyperlink={request.bookingDetails} data-f-color={request.bookingDetails?'FF0000FF':''}>
                            {request.bookingDetails ? <a href={request.bookingDetails} target='_blank' rel='noopener noreferrer'>View file</a> : 'NA'}
                          </td>
                          <td rowSpan={request.passengersName.length}>{request.remarks}</td>
                          <td rowSpan={request.passengersName.length}>{request.cancelReason}</td>
                          <td rowSpan={request.passengersName.length} data-hyperlink={request.cancelProof} data-f-color={request.cancelProof?'FF0000FF':''}>
                            {request.cancelProof ? <a href={request.cancelProof} target='_blank' rel='noopener noreferrer'>View file</a> : 'NA'}
                          </td>
                          <td rowSpan={request.passengersName.length}>{request.refundAmount}</td>
                          <td rowSpan={request.passengersName.length}>{request.lostAmount}</td>
                        </tr>
                      )}
                      {passengerIndex !== 0 && (
                        <tr key={`${request.id}-${passengerIndex}`}>
                          <td>{passenger.name}-{passenger.type}</td>
                          <td>{passenger.project}</td>
                          <td>{passenger.vertical}</td>
                          <td>{passenger.purpose}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="report-page">
        <div className='report-header'>
          <h2>Summary</h2>
          <div className='search-filter'>
          {showFilters && 
            <div className={`filter-options ${showFilters ? 'open-menu' : ''}`}>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requests ? 'open-menu' : ''}`}>
                  {['All', 'My Requests'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requests.includes(option)}
                        onChange={() => handleFilterChange('requests', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requests')}>Requests</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requesterType ? 'open-menu' : ''}`}>
                  { ['All', 'Assessor', 'Proctor', 'SME', 'Employee', 'Employee_Assessor','Trainer', 'Counselor', 'Intern'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requesterType.includes(option)}
                        onChange={() => handleFilterChange('requesterType', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requesterType')}>Requester Type</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.supportType ? 'open-menu' : ''}`}>
                  {['All', 'Send Courier', 'Receive Courier'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.supportType.includes(option)}
                        onChange={() => handleFilterChange('supportType', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('supportType')}>Support Type</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.status ? 'open-menu' : ''}`}>
                  {['All', 'Open', 'Closed'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.status.includes(option)}
                        onChange={() => handleFilterChange('status', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('status')}>Open/Closed</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.requestStatus ? 'open-menu' : ''}`}>
                  {['All', 'Pending', 'Accepted', 'Denied', 'Cancelled'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.requestStatus.includes(option)}
                        onChange={() => handleFilterChange('requestStatus', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('requestStatus')}>Request Status</button>
            </div>
            <div className="dropdown">
                <div className={`dropdown-content ${dropdownVisible.bookingStatus ? 'open-menu' : ''}`}>
                  {['All', 'Success', 'Fail', 'Pending', 'Cancelled'].map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={filterOptions.bookingStatus.includes(option)}
                        onChange={() => handleFilterChange('bookingStatus', option)}
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <button onClick={() => toggleDropdown('bookingStatus')}>Booking Status</button>
            </div>
            </div>}
            <div className="search-container">
            <div className="search-fields">
              <label>
                <strong>Search By:</strong>
                <select value={searchHeader} onChange={handleSearchHeaderChange}>
                  <option value="requesterName">Requester Name</option>
                  <option value="requestId">Request ID</option>
                  <option value="requestDate">Request Date</option>
                  <option value="officeLocation">Office Location</option>
                  <option value="fromLocation">From Location</option>
                  <option value="toLocation">To Location</option>
                  <option value="sentFrom">Sent From</option>
                  <option value="receivedBy">Received By</option>
                  <option value="bookingBy">Booking By</option>
                  <option value="bookingDate">Booking Date</option>
                  <option value="amount">Amount</option>
                  <option value="podNumber">POD Number</option>
                  <option value="courierCo">Courier Company</option>
                  <option value="remarks">Remarks</option>
                  <option value="cancelReason">Cancel Reason</option>
                  <option value="refundAmount">Refund Amount</option>
                  <option value="lostAmount">Lost Amount</option>
                </select>
              </label>
              {!['requestDate', 'approvedDate', 'bookingDate', 'date'].includes(searchHeader) ? 
                <div className='search-input'>
                  <strong>Search Here:</strong>
                  <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder='Search here...'/>
                </div> :
                <div className='search-input'>
                  <strong>Search Here:</strong>
                  <DatePicker
                    selected={dateRange.start}
                    onChange={(dates) => setDateRange({
                      start: dates[0],
                      end: dates[1]
                    })}
                    startDate={dateRange.start}
                    endDate={dateRange.end}
                    selectsRange
                    className='date-picker'
                  />
                </div>
              }
             </div>
            </div>
            <button className='filter-button' onClick={handleFilterButtonClick}>
              <VscSettings />
              <span><strong>Filter</strong></span>
            </button>
            <button className='filter-button' onClick={handleClearFilters}>
              <FaDeleteLeft />
              <span><strong>Clear</strong></span>
            </button>
          </div>
         
          <button className='download-button' onClick={() => handleDownload('Courier')}>
            <FaDownload />
            <span>Download</span>
          </button>
         
        </div>
        <div className="report-table">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th data-f-bold='true'>S.No</th>
                <th data-f-bold='true'>Request Date</th>
                <th data-f-bold='true'>Request ID</th>
                <th data-f-bold='true'>Requester Name</th>
                <th data-f-bold='true'>Requester Type</th>
                <th data-f-bold='true'>Request Date</th>
                <th data-f-bold='true'>Support Needed</th>
                <th data-f-bold='true'>Courier Type</th>
                <th data-f-bold='true'>Office Location</th>
                <th data-f-bold='true'>From Location</th>
                <th data-f-bold='true'>To Location</th>
                <th data-f-bold='true'>Sent From</th>
                <th data-f-bold='true'>Received By</th>
                <th data-f-bold='true'>Received Status</th>
                <th data-f-bold='true'>Received On</th>
                <th data-f-bold='true'>Status</th>
                <th data-f-bold='true'>Booking Status</th>
                <th data-f-bold='true'>POD Number</th>
                <th data-f-bold='true'>Courier Company</th>
                <th data-f-bold='true'>Amount (In Rs)</th>
                <th data-f-bold='true'>Booking By</th>
                <th data-f-bold='true'>Booking Date</th>
                <th data-f-bold='true'>Booking Details</th>
                <th data-f-bold='true'>Remarks</th>
                <th data-f-bold='true'>Cancel Reason</th>
                <th data-f-bold='true'>Cancel Proof</th>
                <th data-f-bold='true'>Refund Amount (In Rs)</th>
                <th data-f-bold='true'>Lost Amount (In Rs)</th>
              </tr>
            </thead>
            
            <tbody>
              {loading ? <p className='loading'>Loading...</p> :
              filteredData && filteredData.map((request) => (
                request.supportNeeded.includes('Courier') &&
                <React.Fragment key={request.id}>
                   <tr key={`${request.id}-${courierIndex}`}>
                      <td>{courierIndex++}</td>
                      <td>{request.requestDate}</td>
                      <td>{request.id}</td>
                      <td>{request.requesterName}</td>
                      <td>{request.requesterType}</td>
                      <td>{request.requestDate}</td>
                      <td>{request.supportNeeded}</td>
                      <td>{request.courierType || 'NA'}</td>
                      <td>{request.officeLocation || 'NA'}</td>
                      <td>{request.fromLocation || 'NA'}</td>
                      <td>{request.toLocation || 'NA'}</td>
                      <td>{request.sentFrom || 'NA'}</td>
                      <td>{request.receivedBy || 'NA'}</td>
                      <td>{request.receivedStatus || 'NA'}</td>
                      <td>{request.receivedOn || 'NA'}</td>
                      <td>{request.status}</td>
                      <td>{request.bookingStatus || 'NA'}</td>
                      <td>{request.podNumber || 'NA'}</td>
                      <td>{request.courierCo || 'NA'}</td>
                      <td>{request.amount || 'NA'}</td>
                      <td>{request.bookingBy || 'NA'}</td>
                      <td>{request.bookingDate || 'NA'}</td>
                      <td data-hyperlink={request.bookingDetails} data-f-color={request.bookingDetails?'FF0000FF':''}>
                        {request.bookingDetails ? <a href={request.bookingDetails} target='_blank' rel='noopener noreferrer'>View file</a> : 'NA'}
                      </td>
                      <td>{request.remarks || 'NA'}</td>
                      <td>{request.cancelReason || 'NA'}</td>
                      <td data-hyperlink={request.cancelProof} data-f-color={request.cancelProof?'FF0000FF':''}>
                        {request.cancelProof ? <a href={request.cancelProof} target='_blank' rel='noopener noreferrer'>View file</a> : 'NA'}
                      </td>
                      <td>{request.refundAmount || 'NA'}</td>
                      <td>{request.lostAmount || 'NA'}</td>
                    </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </Tabs>
      <Footer />
    </div>
  );
};

export default Report;
