import React, { useState, useEffect, useContext } from 'react';
import './styles/FullDetails.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { UserContext } from '../UserContext';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';// import Swiper core and required modules
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {FaDownload} from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Formatting default date
const formatDate = (date) => {
  if (!date) return 'NA';
  const temp = date.split('T')[0];
  return moment(temp).format('DD-MM-YYYY');
};

export default function CourierDetails() {
  const { user, token } = useContext(UserContext);
  const { requestId } = useParams(); // Get request ID from URL params
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    bookingDetails: {},
  });

  // Effect to fetch request data if editing an existing request
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing request data for editing
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 401) {
            // Handle token expiration or unauthorized access
            // Optionally redirect to login page or refresh token
            navigate('/login');
          }
          throw new Error(errorData.error || 'Network response was not ok.');
        }

        const data = await response.json();
        console.log(data);
        setFormData(data);
      } catch (error) {
        if (error.response && error.response.data.error === 'Token expired') {
          // Redirect to login page if token is expired
          navigate('/login');
        } else {
            // Handle other errors
            console.error('Error:', error.message);
        }
      }
    };

    fetchData(); // Call fetchData function
  }, [requestId, token, user]);

  const generatePDF = () => {
    const doc = new jsPDF();
    let yOffset = 10; // Initial vertical offset for text
    const margin = 10; // Page margin
    const pageHeight = doc.internal.pageSize.height; // Height of the page
    const pageWidth = doc.internal.pageSize.width; // Width of the page
    const maxTextHeight = 10; // Maximum text height for a single line
    const sectionHeaderHeight = 15; // Height for section headers
    const labelMargin = 5; // Extra space after the colon for consistent alignment

    // Calculate the maximum width of labels
    const calculateLabelWidth = (labels) => {
        let maxWidth = 0;
        labels.forEach(label => {
            const width = doc.getTextWidth(label);
            if (width > maxWidth) {
                maxWidth = width;
            }
        });
        return maxWidth;
    };

    // Maximum width of labels for consistent alignment
    const maxLabelWidth = calculateLabelWidth([
      'Request ID:', 'Batch Id:', 'Center:', 'Client:', 
      'Cost Center:', 'Support Needed:', 'Courier Type:', 
      'Office Location:', 'Location:', 'Sent From:', 'Received By:', 
      'Received Status:', 'Received On:', 'Booking Date:', 
      'Booking By:', 'Amount:', 'POD Number:', 'Courier Company', 
      'Booking Status:', 'Ticket Details:', 'Remarks:'
    ]) + labelMargin;

    // Helper function to add text with automatic line spacing and center alignment
    const addText = (label, value, isBold = false) => {
        if (yOffset + maxTextHeight > pageHeight - margin) {
            doc.addPage();
            yOffset = margin;
        }
        if (isBold) {
            doc.setFont("Helvetica", "bold");
        } else {
            doc.setFont("Helvetica", "normal");
        }
        
        // Add label
        const labelText = `${label}:`;
        doc.text(labelText, margin, yOffset);
        
        // Add value
        doc.setFont("Helvetica", "normal");
        const valueX = margin + maxLabelWidth;
        doc.text(value, valueX, yOffset);
        
        yOffset += maxTextHeight; // Increase offset for the next line
    };

    // Helper function to add a link with underline and center alignment
    const addLink = (label, linkText, url) => {
        if (yOffset + maxTextHeight > pageHeight - margin) {
            doc.addPage();
            yOffset = margin;
        }
        
        // Calculate the width of the label
        const labelWidth = doc.getTextWidth(`${label}:`);
        const linkTextWidth = doc.getTextWidth(linkText);
        const linkX = margin + maxLabelWidth;
        const linkY = yOffset;
        const linkUnderlineY = yOffset + 1; // Position of the underline

        // Add label
        doc.setFont("Helvetica", "normal");
        doc.setTextColor(0, 0, 0); // Black color for the label
        doc.text(`${label}:`, margin, linkY);

        // Set the link color
        const linkColor = [0, 0, 255]; // Blue color for the link
        doc.setTextColor(linkColor[0], linkColor[1], linkColor[2]); // Link color

        // Add the link text
        doc.textWithLink(linkText, linkX, linkY, { url });

        // Draw the underline
        doc.setDrawColor(linkColor[0], linkColor[1], linkColor[2]); // Set underline color
        doc.line(linkX, linkUnderlineY, linkX + linkTextWidth, linkUnderlineY); // Draw underline
        
        // Revert text color to black
        doc.setTextColor(0, 0, 0);

        // Move yOffset to the next line
        yOffset += maxTextHeight; // Increase offset for the next line
    };

    // Helper function to add a section header and check for space
    const addSectionHeader = (header) => {
        yOffset += 5; // Add extra space before header
        if (yOffset + sectionHeaderHeight > pageHeight - margin) {
            doc.addPage();
            yOffset = margin;
        }
        addText(header, '', true); // Add header text in bold with empty value
    };

    // Helper function to add a line separator
    const addSeparator = () => {
        doc.setDrawColor(200, 200, 200);
        doc.line(margin, yOffset, pageWidth - margin, yOffset);
        yOffset += 5; // Add space below the line
    };

    // Set the title and add it to the document
    yOffset += 5;
    doc.setFontSize(16);
    addText(`Request #${requestId}`, '', true);
    addSeparator();
    doc.setFontSize(12);

    // Request Details
    addSectionHeader("Courier Details");
    addText("Request ID", requestId.toString());
    addText("Support Needed", formData.supportNeeded || 'NA');
    addText("Courier Type", formData.courierType || 'NA');
    addText("Office Location", formData.officeLocation || 'NA');
    addText("Location", `${formData.fromLocation || 'NA'} to ${formData.toLocation || 'NA'}`);
    addText("Sent From", formData.sentFrom || 'NA');
    addText("Received By", formData.receivedBy || 'NA');
    addText("Received Status", formData.receivedStatus || 'NA');
    addText("Received On", formatDate(formData.receivedOn));
    addText("Status", formData.status || 'Pending');
    if(formData.supportNeeded==='Receive Courier'){
    addText("POD Number", formData.bookingDetails.podNumber || 'NA');
    addText("Courier Company", formData.bookingDetails.courierCo || 'NA');
    }
    // Booking Details
    if (formData.supportNeeded === 'Send Courier') {
    addSectionHeader("Booking Details");
    addText("Booking Date", formData.bookingDetails.bookingDate ? formatDate(formData.bookingDetails.bookingDate) : 'NA');
    addText("Booking By", formData.bookingDetails.bookingBy || 'NA');
    addText("Amount", formData.bookingDetails.amount || 'NA');
    addText("POD Number", formData.bookingDetails.podNumber || 'NA');
    addText("Courier Company", formData.bookingDetails.courierCo || 'NA');
    addText("Booking Status", formData.bookingDetails.bookingStatus || 'NA');

    if (formData.bookingDetails.fileUrl) {
        addLink("Ticket Details", formData.bookingDetails.fileName, formData.bookingDetails.fileUrl);
    } else {
        addText("Ticket Details", 'NA');
    }

    addText("Remarks", formData.remarks || 'NA');
    }
    doc.save(`Request#${requestId}.pdf`);
};





  return (
    <div className='Full-Details'>
      <Header />
      <main className='main-content'>
        <div className='download-container'>
          <button className='download-button' onClick={generatePDF}>
            <FaDownload />
            <span>Download as PDF</span>
          </button>
        </div>
        <Swiper 
              // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          className='Swiper'
        >
          <SwiperSlide>
            <div className="section-container">
              <h3  style={{paddingTop: '50px'}}>Courier Details</h3>
              <div><strong>Request ID:</strong> {requestId}</div>
              <div><strong>Support Needed:</strong> {formData.supportNeeded || 'NA'}</div>
              <div><strong>Office Location:</strong> {formData.officeLocation || 'NA'}</div>
              <div><strong>Location:</strong> {`${formData.fromLocation} to ${formData.toLocation}` || 'NA'}</div>
              <div><strong>Sent From:</strong> {formData.sentFrom || 'NA'}</div>
              <div><strong>Received By:</strong> {formData.receivedBy || 'NA'}</div>
              <div><strong>Received Status:</strong> {formData.receivedStatus || 'NA'}</div>
              <div><strong>Received On:</strong> {formData.receivedOn ? formatDate(formData.receivedOn) : 'NA'}</div>
              <div><strong>Status:</strong> {formData.status || 'Pending'}</div>
              {formData.supportNeeded==='Receive Courier' &&
              <>
              <div><strong>POD Number:</strong> {formData.bookingDetails.podNumber || 'NA'}</div>
              <div><strong>Courier Company:</strong> {formData.bookingDetails.courierCo || 'NA'}</div>
              </>}
            </div>
          </SwiperSlide>
          {formData.supportNeeded==='Send Courier' &&
          <SwiperSlide>
            <div className="section-container">
              <h3>Booking Details</h3>
              <div><strong>Booking Date:</strong> {formData.bookingDetails.bookingDate ? formatDate(formData.bookingDetails.bookingDate) : 'NA'}</div>
              <div><strong>Booking By:</strong> {formData.bookingDetails.bookingBy || 'NA'}</div>
              <div><strong>Amount:</strong> {formData.bookingDetails.amount || 'NA'}</div>
              <div><strong>POD Number:</strong> {formData.bookingDetails.podNumber || 'NA'}</div>
              <div><strong>Courier Company:</strong> {formData.bookingDetails.courierCo || 'NA'}</div>
              <div><strong>Booking Status:</strong> {formData.bookingDetails.bookingStatus || 'NA'}</div>
              <div><strong>Ticket Details: </strong>{formData.bookingDetails.fileUrl ? (
                <span className='view-download'>
                  <a href={formData.bookingDetails.fileUrl} target='_blank' rel="noopener noreferrer">
                    {formData.bookingDetails.fileName}
                  </a>
                </span>
              ) : (
                'NA'
              )}</div>
              <div><strong>Remarks:</strong> {formData.remarks || 'NA'}</div>
            </div>
          </SwiperSlide>}
        </Swiper>
      </main>
      <Footer />
    </div>
  )
}
