import React, { useState, useContext, useEffect } from 'react';
import './styles/Request.css'; // Importing custom CSS for styling
import Header from '../components/Header'; // Importing Header component
import Footer from '../components/Footer'; // Importing Footer component
import { useNavigate, useParams } from 'react-router-dom'; // Importing navigation hook
import { UserContext } from '../UserContext'; // Importing UserContext to get user information
import {FaCloudUploadAlt} from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed

function CancelRequest() {
  const { user, token } = useContext(UserContext); // Getting user and token from UserContext
  const navigate = useNavigate(); // Hook for navigation
  const { requestId } = useParams(); // Get request ID from URL params

  // Initial form state
  const [reason, setReason] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [file, setFile] = useState(null); // Track file upload state
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  // Effect to fetch request data if editing an existing request
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch existing request data for editing
        const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/booking-details/${requestId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        });

        if (!response.ok) {
        throw new Error('Failed to fetch request data');
        }

        const data = await response.json();
        setReason(data.cancelReason || '');
        setName(data.cancelProof.split('$')[0] || '');
        setUrl(data.cancelProof.split('$')[1] || '');
      } catch (error) {
        console.error('Error fetching request data:', error);
      }
    };
  
    fetchData(); // Call fetchData function
  
  }, [requestId, token, user]);

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile); // Store the file in state
      setName(uploadedFile.name);
      const temp=URL.createObjectURL(uploadedFile);
      setUrl(temp);
    }
  };

  const handleFileDelete = async() => {
    setFile(null);
    setName('');
    setUrl('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!reason.trim()) {
      await notifyError(NotifyMessages.cancelReasonRequired,'bottom-center');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/cancel-details/${requestId}`, {
        method:'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({reason}),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      await notifySuccess(NotifyMessages.cancelDetailsSubmitted, 'bottom-center');
      setFormSubmitted(true); // Mark form as submitted
      setTimeout(() => {
        navigate('/'); // Navigate to home or appropriate page after success
      }, 2000);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (formSubmitted && file) {
      const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/cancel-details/upload/${requestId}`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
          }

          
          const data = await response.json();
          setName(data.cancelProof.split('$')[0] || '');
          setUrl(data.cancelProof.split('$')[1] || '');
        } catch (error) {
          console.error(`Error updating cancel details for request ${requestId}:`, error);
        }
      };
      uploadFile(); // Upload the file only after form submission
    }
  }, [formSubmitted, file, requestId, token]);

  return (
    <div className="Request">
      <Header />
      <ToastContainer />
      <main className="main-content">
        <form className="reqForm" onSubmit={handleSubmit}>
          <h1>Cancel Request</h1>

          <fieldset>
            <label>
              Cancel Reason:
              <textarea
                name="reason"
                placeholder='Write your reason here...'
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                style={{display:'block',width:'100%',height:'200px',marginTop:'10px', padding:'10px'}}
              />
            </label>
            
            <div className='upload'>
                <label htmlFor={`fileUpload_${requestId}`} className="upload-button">
                <strong>Cancel Proof:</strong> {!name && !url && <FaCloudUploadAlt size={20} />} 
                <span>{name && url ? 
                    <span className='view-download'>
                    <a href={url} target='_blank' rel="noopener noreferrer">
                        {name}
                    </a>
                    </span> 
                : <p className='upload-icon'>Upload</p>}</span>
                <input
                    name="file"
                    type="file"
                    onChange={(e) => handleFileUpload(e,requestId)}
                    id={`fileUpload_${requestId}`}
                    required={!name && !url}
                />
                </label>
                {name && url && 
                    <span className='delete-upload'>
                    <MdDelete onClick={handleFileDelete}/>
                    </span>
                }
            </div>
          </fieldset>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default CancelRequest;
