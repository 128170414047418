//Error Alert Message Notification
import { Bounce, ToastContainer, toast as notify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Error Notification
const notifyError = (message,position) => {
  notify.error(message, {
    position: position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

//Success Notification
const notifySuccess = (message,position) => {
  notify.success(message, {
    position: position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

export { notifyError, notifySuccess, ToastContainer };
