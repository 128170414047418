import React from 'react';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Navriti Technologies &copy; All rights reserved</p>
    </footer>
  );
};

export default Footer;
