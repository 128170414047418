import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { FaLocationArrow } from 'react-icons/fa';
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import './styles/Map.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { UserContext } from '../UserContext'; // Importing UserContext to get user information
import Tabs from '../components/Tabs/Tabs';
import { notifyError, notifySuccess, ToastContainer } from '../components/ErrorAlert'; // Import react-toastify for notifications
import { NotifyMessages } from '../components/NotifyMessages'; // Adjust the path as needed
import moment from 'moment'; // Importing moment.js for date manipulation

const center = { lat: 21.0000, lng: 78.0000 }; // Default center for the Google Map
const libraries = ['places']; // Define libraries outside the component
//Formatting the dates
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.toLocaleDateString('sv-SE')}T${date.toLocaleTimeString('en-GB', { hour12: false }).slice(0, 5)}`;
};

function Map() {
  const navigate=useNavigate();
  const { user, token } = useContext(UserContext); // Accessing user information and authentication token from context
  const { requestId } = useParams(); // Fetching request ID from the URL params
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Loading Google Maps API with API key and necessary libraries
    libraries,
  });

  // States related to travel route
  const [mapLoading, setMapLoading] =useState(false);
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [allRoutes, setAllRoutes] = useState([]); // State to store all routes
  const [selectedRoute, setSelectedRoute] = useState(null); // State to store the selected route
  const [expandedRoute, setExpandedRoute] = useState(null);
  const [travelMode, setTravelMode] = useState('BEST'); // State for transit mode
  const originAutocompleteRef = useRef(null);
  const destinationAutocompleteRef = useRef(null);

  // States related to hotel search
  const [input, setInput] = useState('');
  const [result, setResult] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state for hotel search
  const [flag, setFlag] = useState(false); // Flag for showing/hiding autocomplete dropdown
  const [checkinDate, setCheckinDate] = useState('');
  const [checkoutDate, setCheckoutDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null }); // Coordinates for location
  const [sortBy, setSortBy] = useState('');

  // Effect to fetch request details based on requestId from URL
  useEffect(() => {
    if (requestId) {
      fetchRequestDetails();
    }
  }, [requestId, token]);

  // Effect to trigger hotel search when coordinates are updated
  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      searchHotelsByCoordinates(coordinates.lat, coordinates.lng);
    }
  }, [coordinates]);

  // Function to fetch request details based on requestId
  async function fetchRequestDetails() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${requestId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const requestData = await response.json();
      if (requestData.supportNeeded === 'Travel') {
        setOrigin(requestData.source || '');
        setDestination(requestData.destination);
        setArrivalDate(formatDate(requestData.fromDate));
      } else if (requestData.supportNeeded === 'Accommodation') {
        setInput(requestData.destination);
        setCheckinDate(requestData.fromDate.split('T')[0]);
        setCheckoutDate(requestData.toDate.split('T')[0]);
      } else if (requestData.supportNeeded === 'Travel + Accommodation') {
        setOrigin(requestData.source || '');
        setDestination(requestData.destination);
        setArrivalDate(formatDate(requestData.fromDate));
        setInput(requestData.destination);
        setCheckinDate(requestData.fromDate.split('T')[0]);
        setCheckoutDate(requestData.toDate.split('T')[0]);
      }
      // Fetch predictions for autocomplete based on destination
      const url = `${process.env.REACT_APP_OLA_API_URL}/autocomplete?input=${requestData.destination}&api_key=${process.env.REACT_APP_OLA_API_KEY}`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OLA_API_KEY}` // Ensure proper authorization header if needed
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setResult(data.predictions);
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
      
    } catch (error) {
      if (error.response && error.response.data.error === 'Token expired') {
        // Redirect to login page if token is expired
        navigate('/login');
      } else {
          // Handle other errors
          console.error('Error fetching request details:', error);
      }
    }
  }

  // Loading indicator while Google Maps is loading
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  async function getNearestStation(searchTerm) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/places?query=${encodeURIComponent(searchTerm)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      return result[0].formatted_address;
    } catch (error) {
      console.error('Fetch error:', error.message);
    }
  }
  
  //Function to calculate all Routes
  async function getRoute(origin, destination, travelMode){
    if (origin === '' || destination === '') {
      notifyError(NotifyMessages.selectOriginDestination , 'bottom-center');
      return;
    }
  
    const directionsService = new window.google.maps.DirectionsService();
    try {
      const results = await directionsService.route({
        origin,
        destination,
        travelMode: travelMode,
        provideRouteAlternatives: true,
        transitOptions: {
          modes: ['RAIL', 'BUS'],
          arrivalTime: new Date(arrivalDate),
        },
      });
  
      if (results.status === 'ZERO_RESULTS') {
        notifyError(NotifyMessages.noRoutesFound, 'bottom-center');
        return;
      }
      results.routes.sort((a, b) => {
        // Assuming each route has only one leg
        const durationA = a.legs[0].duration.value;
        const durationB = b.legs[0].duration.value;
        return durationA - durationB;
      });
      return results;
    } catch (error) {
      // Handle errors from the Directions API
      console.error('Error fetching directions:', error);
    }
  }
  // Function to calculate route using Directions API
  async function calculateRoute() {
    if (origin === '' || destination === '') {
      notifyError(NotifyMessages.selectOriginDestination, 'bottom-center');
      return;
    }
    setMapLoading(true);
  
    try {
      if (travelMode === 'BEST') {
        const nearBy = await getNearestStation(origin);
        const carRoutes = await getRoute(origin, destination, 'DRIVING');
        const busRoutes = await getRoute(nearBy, destination, 'TRANSIT');
        const bikeRoutes = await getRoute(origin, destination, 'TWO_WHEELER');
        
        let validRoutes = [];
        if (carRoutes && carRoutes.routes && carRoutes.routes.length > 0) {
          validRoutes.push({ ...carRoutes.routes[0], travelMode: 'Car' });
        }
        if (busRoutes && busRoutes.routes && busRoutes.routes.length > 0) {
          validRoutes.push({ ...busRoutes.routes[0], travelMode: 'Bus' });
        }
        if (bikeRoutes && bikeRoutes.routes && bikeRoutes.routes.length > 0) {
          validRoutes.push({ ...bikeRoutes.routes[0], travelMode: 'Bike' });
        }
        setAllRoutes(validRoutes);
        setDirectionsResponse(carRoutes);
      } else if (travelMode === 'TRANSIT') {
        const nearBy = await getNearestStation(origin);
        const results = await getRoute(nearBy, destination, travelMode);
        const updatedRoutes = results && results.routes.map((route) => ({
          ...route,
          travelMode: 'Bus',
        }));
        setAllRoutes(updatedRoutes);
        setDirectionsResponse(results);
      } else {
        const results = await getRoute(origin, destination, travelMode);
        const updatedRoutes = results.routes.map((route) => ({
          ...route,
          travelMode: travelMode === 'DRIVING' ? 'Car' : travelMode === 'WALKING' ? 'Walk' : 'Bike',
        }));
        setAllRoutes(updatedRoutes);
        setDirectionsResponse(results);
      }
    } catch (error) {
      console.error('Error calculating route:', error);
      notifyError('An error occurred while calculating the route. Please try again.', 'bottom-center');
    } finally {
      setMapLoading(false); // Ensure loading state is reset
    }
  }
  
  // Function to handle route card selection
  const handleRouteSelection = (selectedRoute) => {
    setSelectedRoute(selectedRoute); // Store the selected route
    setExpandedRoute(expandedRoute === selectedRoute ? null : selectedRoute);  // Toggle details visibility
    setDirectionsResponse(prev => ({
      ...prev,
      routes: [selectedRoute] // Set the directions response to only include the selected route
    }));
  };

  // Function to clear current route and reset states
  function clearRoute() {
    setDirectionsResponse(null);
    setAllRoutes(null);
    setOrigin('');
    setDestination('');
  }

  // Function to handle input change for autocomplete
  const handleInput = async (val) => {
    setInput(val);
    setFlag(true);

    const url = `${process.env.REACT_APP_OLA_API_URL}/autocomplete?input=${val}&api_key=${process.env.REACT_APP_OLA_API_KEY}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OLA_API_KEY}` // Ensure proper authorization header if needed
        }
      });
      const data = await response.json();
      setResult(data.predictions);
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  };

  // Function to handle selection of a prediction from autocomplete
  const handlePrediction = async (prediction) => {
    setInput(prediction.description);
    setResult([]);
    setFlag(false);

    // Fetch place details based on place_id
    const url = `${process.env.REACT_APP_OLA_API_URL}/details?place_id=${prediction.place_id}&api_key=${process.env.REACT_APP_OLA_API_KEY}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OLA_API_KEY}` // Ensure proper authorization header if needed
        }
      });
      const data = await response.json();

      // Use the retrieved data to set coordinates
      if (data.result.geometry && data.result.geometry.location) {
        const { lat, lng } = data.result.geometry.location;
        setCoordinates({ lat, lng });
      }
    } catch (error) {
      notifyError(NotifyMessages.selectLocation, 'bottom-center');
      console.error('Error fetching place details:', error);
      return;
    }
  };

  // Function to search hotels based on coordinates and other parameters
  const searchHotelsByCoordinates = async (latitude, longitude) => {
    const today = moment().startOf('day');
    if (moment(checkinDate).isBefore(today, 'day')) {
      notifyError(NotifyMessages.checkinValidation, 'bottom-center');
      return;
    }
    if (adults < 1 || rooms < 1) {
      notifyError(NotifyMessages.adultValidation, 'bottom-center');
      return;
    }
    if (adults < rooms) {
      notifyError(NotifyMessages.roomValidation, 'bottom-center');
      return;
    }
    if (!latitude || !longitude) {
      if (result.length > 0) {
        await handlePrediction(result[0]);
      } else {
        notifyError(NotifyMessages.selectLocation, 'bottom-center');
        return;
      }
    }
    if (!sortBy) {
      notifyError(NotifyMessages.sortBySelection, 'bottom-center');
      return;
    }

    setLoading(true);
    const hotelSearchUrl = process.env.REACT_APP_HOTEL_URL;
    const params = {
      include_adjacency: 'true',
      order_by: sortBy,
      longitude: longitude,
      latitude: latitude,
      room_number: rooms,
      adults_number: adults,
      locale: 'en-gb',
      checkin_date: checkinDate,
      checkout_date: checkoutDate,
      page_number: 0,
      filter_by_currency: 'INR',
      units: 'metric'
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${hotelSearchUrl}?${queryString}`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': process.env.REACT_APP_BOOKING_API_KEY,
        'x-rapidapi-host': process.env.REACT_APP_BOOKING_API_HOST
      }
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setHotels(data.result);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    } finally {
      setLoading(false); // End loading state
    }
  };

  // Function to generate a dynamic URL for booking a hotel
  const generateHotelUrl = (hotel) => {
    const params = {
      ss: hotel.city_name,
      ssne: hotel.city_name,
      ssne_untouched: hotel.city_name,
      highlighted_hotels: hotel.hotel_id,
      efdco: 1,
      label: 'gen173rf-1FCAsobEIbdDEwLWhvdGVscy1wcml2YXRlLWxpbWl0ZWVkSDNYA2hsiAEBmAExuAEXyAEM2AEB6AEB-AECiAIBogIObG9jYWxob3N0OjMwMDCoAgO4Avf_k7QGwAIB0gIkMzUyNzVlNTAtZWJlNy00YTEyLWFlYTgtY2FkYTE5MGMwOWJl2AIF4AIB',
      aid: 304142,
      lang: 'en-us',
      sb: 1,
      src_elem: 'sb',
      src: 'hotel',
      dest_id: hotel.city_id,
      dest_type: 'city',
      checkin: checkinDate,
      checkout: checkoutDate,
      group_adults: adults,
      no_rooms: rooms,
      group_children: children
    };
    const queryString = new URLSearchParams(params).toString();
    return `https://www.booking.com/searchresults.html?${queryString}`;
  };

  function handleOriginPlaceChanged() {
    const place = originAutocompleteRef.current?.getPlace()
    if (place?.formatted_address) {
      setOrigin(place.formatted_address)
    }
  }

  function handleDestinationPlaceChanged() {
    const place = destinationAutocompleteRef.current?.getPlace()
    if (place?.formatted_address) {
      setDestination(place.formatted_address)
    }
  }
  
  // Data for tabs to switch between Travel and Hotel views
  const tabData = [
    { label: "Travel" },
    { label: "Hotel" },
  ];

  return (
    <div className="container">
      <Header /> {/* Header component */}
      <ToastContainer /> {/* Error notification component */}
      <div id="map">
        {/* GoogleMap component */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={map => setMap(map)}
        >
          {/* <Marker position={center} />  */}
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
      <Tabs tabs={tabData}>
        <div className="controls">
          <div className="input-group">
            {/* Autocomplete for Origin */}
            <label htmlFor='origin'><b>Origin:</b>
              <Autocomplete
                onLoad={autocomplete => (originAutocompleteRef.current = autocomplete)}
                onPlaceChanged={handleOriginPlaceChanged}
              >
                <input
                  type="text"
                  id="origin"
                  placeholder="Starting"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
              </Autocomplete>
            </label>
            {/* Autocomplete for Destination */}
            <label htmlFor='destination'><b>Destination:</b>
              <Autocomplete
                onLoad={autocomplete => (destinationAutocompleteRef.current = autocomplete)}
                onPlaceChanged={handleDestinationPlaceChanged}
              >
                <input
                  type="text"
                  id="destination"
                  placeholder="Ending"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                />
              </Autocomplete>
            </label>

            <label htmlFor="travelMode"><b>Travel Mode:</b>
              <select
                id="travelMode"
                value={travelMode}
                onChange={(e) => setTravelMode(e.target.value)}
              >
                  <option value="BEST">Best Routes</option>
                  <option value="DRIVING">Car</option>
                  <option value="TRANSIT">Bus</option>
                  <option value="TWO_WHEELER">Bike</option>
                  <option value="WALKING">Walk</option>
                </select>
            </label>
            {/* Input for Departure Time */}
            <label htmlFor="arrivalDate"><b>Arrival Time:</b>
              <input
                type="datetime-local"
                min={formatDate(new Date())}
                id="arrivalDate"
                value={arrivalDate}
                onChange={(e) => setArrivalDate(e.target.value)}
              />
            </label>
          </div>
          <div className="info">
            <span>
              <button onClick={calculateRoute} className='route-buttons'>Get Route</button>
              <button onClick={clearRoute} className='clear-button'>
                <FaDeleteLeft style={{marginRight:'3px'}}/>
                Clear
              </button>
            </span>
            <button
              className="icon-button"
              onClick={() => {
                map.panTo(center);
                map.setZoom(5);
              }}
            >
              <FaLocationArrow />
            </button>
          </div>
          {/* New section for displaying all routes */}
          <div className="route-list">
          <h3>Route Details:</h3>
          {mapLoading ? (
                <p>Loading routes...</p>) :
                allRoutes && allRoutes.length > 0 ? (
            <ul>
              {allRoutes.map((route, index) => (
                <li 
                  key={index}  
                  className={`route-card ${selectedRoute === route ? 'selected' : ''}`}  
                  onClick={() => handleRouteSelection(route)}
                >
                  <h3>Via {route.travelMode}</h3>
                  {route.travelMode === 'Bus' && <>
                    <p>{route.legs[0].departure_time.text} ({moment(route.legs[0].departure_time.value).format('DD-MM-YYYY')}) to </p>
                    <p>{route.legs[0].arrival_time.text} ({moment(route.legs[0].arrival_time.value).format('DD-MM-YYYY')})</p>
                  </>}
                  <p><b>Distance:</b> {route.legs[0].distance.text}</p>
                  <p><b>Duration:</b> {route.legs[0].duration.text}</p>
                  <div>
                    {route.travelMode === 'Bus' && route.legs[0].steps.map((e, i) => (
                      e.transit ? (
                        <p key={i} className='travels-name'>{e.transit.line.agencies[0].name}</p>
                      ) : null
                    ))}
                  </div>
                  <button className='show-steps' onClick={() => handleRouteSelection(route)}>
                    {expandedRoute === route ? 'Hide Details' : 'Show Details'}
                  </button>
                  {expandedRoute === route && (
                    <div className="route-steps">
                      <h4>{route.legs[0].start_address}</h4>
                      {route.legs[0].steps.map((step, i) => (
                        <div key={i} className="each-step">
                          <p>step {i+1}</p>
                          <p></p>
                          <div
                            dangerouslySetInnerHTML={{ __html: step.instructions }}
                          />
                          <p><b>Distance:</b> {step.distance.text}</p>
                          <p><b>Duration:</b> {step.duration.text}</p>
                          {step.transit && 
                          <div>
                            <p className='travels-name'>{step.transit.line && step.transit.line.agencies[0].name}</p>
                            <p>{step.transit.headsign && step.transit.headsign}</p>
                            <p>{step.transit.departure_time.text}-Depart {step.transit.departure_stop.name}</p>
                            <p>{step.transit.arrival_time.text}-Arrive {step.transit.arrival_stop.name}</p>
                          </div>}
                        </div>
                      ))}
                      <h4>{route.legs[0].end_address}</h4>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No routes available</p>
          )}
        </div>

        </div>
        {/* Controls for Hotel search */}
        <div className='controls'>
          <div className='hotel-container'>
            <div className='row'>
              <div className='input-group'>
                {/* Input for entering location */}
                <label htmlFor='location'><b>Location:</b>
                  <input
                    type='text'
                    id='location'
                    placeholder='Enter Location'
                    value={input}
                    onChange={(e) => {
                      handleInput(e.target.value);
                    }}
                    required
                  />
                </label>
                {/* Dropdown for autocomplete predictions */}
                {flag && (
                  <div className='dropdown-container'>
                    <ul className='predictions-dropdown'>
                      {result.length > 0 ? (
                        result.map((prediction, index) => (
                          <li
                            key={index}
                            onClick={() => handlePrediction(prediction)}
                          >
                            {prediction.description}
                          </li>
                        ))
                      ) : (
                        <li className='no-results'>No results found</li>
                      )}
                    </ul>
                  </div>
                )}
                {/* Input for Check-in date */}
                <label htmlFor='checkindate'><b>Checkin-date:</b>
                  <input
                    type='date'
                    id='checkindate'
                    placeholder='Check-in date'
                    value={checkinDate}
                    onChange={(e) => setCheckinDate(e.target.value)}
                    required
                  />
                </label>
                {/* Input for Check-out date */}
                <label htmlFor='checkoutdate'><b>Checkout-date:</b>
                  <input
                    type='date'
                    id='checkoutdate'
                    placeholder='Check-out date'
                    value={checkoutDate}
                    onChange={(e) => setCheckoutDate(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='input-group new'>
                {/* Input for number of Adults */}
                <label htmlFor='adults'><b>Adults:</b>
                  <input
                    className='numberof'
                    type='number'
                    id='adults'
                    placeholder='No. of adults'
                    value={adults}
                    onChange={(e) => setAdults(parseInt(e.target.value))}
                  />
                </label>
                {/* Input for number of Rooms */}
                <label htmlFor='rooms'><b>Rooms:</b>
                  <input
                    className='numberof'
                    type='number'
                    id='rooms'
                    placeholder='No.of rooms'
                    value={rooms}
                    onChange={(e) => setRooms(parseInt(e.target.value))}
                  />
                </label>
                <label htmlFor="sortBy"><b>Sort By:</b>
                    <select
                      id="sortBy"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      required
                    >
                        <option value="">--Select one--</option>
                        <option value="price">Price</option>
                        <option value="popularity">Popularity</option>
                        <option value="distance">Distance</option>
                        <option value="review_score">Customer Rating</option>
                      </select>
                  </label>
                {/* Button to search for Hotels */}
                <button
                  className='search-button'
                  onClick={() => {
                    searchHotelsByCoordinates(coordinates.lat, coordinates.lng);
                  }}
                >
                  Search Hotels
                </button>
              </div>
            </div>
            <div className='hotel-info'>
              {/* Displaying hotel information */}
              {loading ? (
                <p>Loading hotels...</p>
              ) : hotels && hotels.length > 0 ? (
                <div className='hotel-card'>
                  <h2>Hotels:</h2>
                  <ul>
                    {hotels.map((hotel, index) => (
                      <li key={index}>
                        <div class="hotel-name">{hotel.hotel_name}</div>
                        <div class="hotel-address">{hotel.address}{', '}{hotel.city}</div>
                        <div class="hotel-distance">{parseFloat(hotel.distance)}{' kms'}</div>
                        <div class="hotel-distance">Rs.{hotel.price_breakdown.all_inclusive_price}</div>
                        {/* Link to book the hotel */}
                        <a
                          href={generateHotelUrl(hotel)}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='booking-url'
                        >
                          Book now
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No Hotels Found</p>
              )}
            </div>
          </div>
        </div>
      </Tabs>
      <Footer /> {/* Footer component */}
    </div>
  );
}

export default Map;
