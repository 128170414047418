import React, { useState, useContext } from 'react';
import './styles/Header.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { app } from './firebaseAuth';
import { UserContext } from '../UserContext';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logout from '../assets/logout.png'
import white from '../assets/white.png'
import Cookies from 'universal-cookie';
import userLogo from '../assets/user.png';

const cookies = new Cookies();

const Header = () => {
  const {user, setUser, setToken, setRefreshToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  

  const handleClick = async () => {
    const auth = getAuth(app);

    await signOut(auth)
      .then(() => {
        setUser(null);
        setToken('');
        setRefreshToken('');

        // Clear user and token from cookies
        cookies.remove('user');
        cookies.remove('token');
        cookies.remove('refreshToken');

        navigate('/login');
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <nav>
      <img src={white} className='logo' alt='logo'></img>
      <ul>
        <li>
          <NavLink to='/' className='link-items' activeclassname='active'>
            Request
          </NavLink>
        </li>
        <li>
          <NavLink to='/planner' className='link-items' activeclassname='active'>
            Planner
          </NavLink>
        </li>
        {user.role !== 'USER' && 
        <li>
          <NavLink to='/report' className='link-items' activeclassname='active'>
            Report
          </NavLink>
        </li>}
      </ul>
      <img src={userLogo} className='user-pic' onClick={toggleDropdown} alt='user-pic'></img>
      <div className={`sub-menu-wrap ${dropdownOpen ? 'open-menu' : ''}`}>
        <div className='sub-menu'>
          <div className='user-content'>
            <img src={userLogo} alt='avatar'></img>
            <div className='user-info'>
              <p>{user.name ? user.name : ''}{user.role!=='USER' && <span>({user.role})</span>}</p>
              <p>{user.email}</p>
              <p>{user.type ? user.type : ''}</p>
              <p>{user.department ? user.department : ''}</p>
              {user.role==='SUPER_ADMIN' && <Link to='/create-admin' target='_blank'>Create Admin</Link>}
            </div>
          </div>
          <hr />
          <div className='sub-menu-link' onClick={handleClick}>
            <img src={logout} alt='logout'></img>
            <p>Logout</p>
            <span>{'>'}</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
